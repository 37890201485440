import {
  getViewRolesWithoutOwner,
  RoleDisplayText,
  RoleType,
} from '@spectral/types'
import { Button, Modal } from 'antd'
import React, { useMemo } from 'react'
import {
  ContentPanel,
  MultiSelectFieldSection,
  PanelForm,
  SelectFieldSection,
  SwitchFieldSection,
} from '../../components/panels/content'
import EmbeddedHidden from '../../containers/embeded-hidden/embeded-hidden'
import { useSelector } from 'react-redux'
import { select } from '../../redux/store'

const EditMemberModal = ({
  member,
  onOk,
  onClose,
  orgTeams,
  onRoleChange,
  onChangeMemberAccessibility,
  onChangeOrgTeams,
  omRemove,
  visible,
  membersSeeAllAssets,
}) => {
  const appUser = useSelector((state) => state.Auth.user)
  const isSettingRole = useSelector(select.Users.isSettingRole)
  const isSettingOrgTeams = useSelector(select.Users.isSettingOrgTeams)
  const isSettingAccessibility = useSelector(
    select.Users.isSettingAccessibility
  )

  const roleTypes = useMemo(
    () =>
      getViewRolesWithoutOwner().map((role) => ({
        value: role,
        label: RoleDisplayText[role],
        key: role,
      })),
    []
  )

  const orgTeamsOptions = useMemo(
    () =>
      orgTeams.map((team) => ({
        value: team.name,
        name: team.name,
        label: team.name,
        pid: member.pid,
        key: `select_org_team_${team.orgTeamId}`,
      })),
    [orgTeams, member.pid]
  )

  const isTeamlessAdmin =
    member.role === RoleType.Admin && member.orgTeams.length === 0

  return (
    <Modal
      title={`Edit user - ${member.firstName} ${member.lastName}`}
      visible={visible}
      onOk={onOk}
      closable
      onCancel={onClose}
      width={1000}
      destroyOnClose
      footer={null}
    >
      <ContentPanel>
        <EmbeddedHidden>
          {member.pid !== appUser.pid && (
            <PanelForm
              onSubmit={onRoleChange}
              initialValues={{
                role: member.role,
              }}
            >
              <SelectFieldSection
                loading={isSettingRole}
                name="role"
                label="User role"
                options={roleTypes}
                submit
              />
            </PanelForm>
          )}
        </EmbeddedHidden>
        {appUser.role === RoleType.Owner && !membersSeeAllAssets && (
          <PanelForm
            onSubmit={onChangeMemberAccessibility}
            initialValues={{
              canAccessAllAssets: member.canAccessAllAssets,
            }}
          >
            <SwitchFieldSection
              loading={isSettingAccessibility}
              name="canAccessAllAssets"
              label="Assets visibility"
              description="When this setting is on, Spectral will ignore user-team mappings and will allow the user to access all the assets associated to this account"
              submit
              checkedChildren="All assets"
              uncheckedChildren="Teams assets"
              disabled={isTeamlessAdmin}
            />
          </PanelForm>
        )}
        {
          <PanelForm
            onSubmit={onChangeOrgTeams}
            initialValues={{
              orgTeams: member.orgTeams,
            }}
          >
            <MultiSelectFieldSection
              loading={isSettingOrgTeams}
              name="orgTeams"
              label="Assign to teams"
              description="Restrict the user to assets within specific teams in your organization"
              submit
              options={orgTeamsOptions}
              placeholder="Select teams"
            />
          </PanelForm>
        }
      </ContentPanel>
      {appUser.username !== member.username && (
        <EmbeddedHidden>
          <Button
            key="remove"
            danger
            onClick={omRemove}
            style={{
              width: '150px',
            }}
          >
            Remove user
          </Button>
        </EmbeddedHidden>
      )}
    </Modal>
  )
}

export default EditMemberModal
