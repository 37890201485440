import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'theme-ui'
import debounce from 'lodash/debounce'
import { select } from '../../../../redux/store'
import MembersManagement from '../../../../blocks/settings/teams/team-members/view'
import { LinkButton } from '../../../../components'
import { paths } from '../../../../routes'
import track from '../../../../common/track'

const PAGE_SIZE = 10

const TeamMembersConnected = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [emailFilter, setEmailFilter] = useState()
  const currentTeam = useSelector(select.Teams.current)
  const appUser = useSelector((state) => state.Auth.user)
  const isSSOConfigured = useSelector(select.Teams.isSSOConfigured)
  const { users, totalUsers, isLoadingUsers } = useSelector(
    select.Users.listUsers
  )
  const allOrgTeams = useSelector(select.Teams.orgTeams)
  const { inviteError, invites, isLoadingInvites } = useSelector(
    select.Invites.getInvites
  )
  const isLimitedToAddContributors = useSelector(
    select.Plan.isLimitedToAddContributors
  )
  const { maxContributors } = useSelector(select.Plan.currentPlanConfig)

  useEffect(() => {
    if (!currentTeam) dispatch.Teams.fetchCurrentTeam()
    dispatch.Teams.fetchOrgTeams()
    dispatch.Invites.fetchInvites()
  }, [dispatch.Teams, currentTeam, dispatch.Invites, dispatch.Users])
  const debouncedFetchUsers = useCallback(
    debounce((params) => dispatch.Users.fetchUsers(params), 400),
    []
  )
  const fetchUsersWithQuery = () =>
    debouncedFetchUsers({
      pagination: {
        page,
        pageSize: PAGE_SIZE,
      },
      filters: {
        email: emailFilter,
      },
    })
  useEffect(() => {
    fetchUsersWithQuery()
  }, [dispatch.Users, emailFilter, page])

  const onFilterChanged = useCallback(
    (email) => {
      track.filterMembers()
      setPage(1)
      setEmailFilter(email)
    },
    [track]
  )

  const removeMember = useCallback(
    async (email) => {
      await dispatch.Users.deleteByEmail(email)
      if (users.length === 1) {
        setPage(page - 1)
      }
      fetchUsersWithQuery()
    },
    [dispatch, users.length, page, fetchUsersWithQuery]
  )

  const changeMemberAccessibility = useCallback(
    (canAccessAllAssets, userPid) =>
      dispatch.Users.setAccessibility({ canAccessAllAssets, userPid }),
    [dispatch]
  )

  const changeUserOrgTeams = useCallback(
    (orgTeams, member) =>
      dispatch.Users.setOrgTeams({ orgTeams, userPid: member.pid }),
    [dispatch]
  )

  const changeMemberRole = useCallback(
    (role, options) => dispatch.Users.setRole({ role, userPid: options.pid }),
    [dispatch]
  )

  const sendInvite = useCallback((data) => dispatch.Invites.addInvite(data), [
    dispatch,
  ])

  const removeInvite = useCallback(
    (email) => dispatch.Invites.removeInvite(email),
    [dispatch]
  )

  return (
    <MembersManagement
      membersSeeAllAssets={currentTeam.membersSeeAllAssets}
      users={users}
      isLoadingUsers={isLoadingUsers}
      page={page}
      pageSize={PAGE_SIZE}
      totalUsers={totalUsers}
      emailFilter={emailFilter}
      onFilterChanged={onFilterChanged}
      onPageChanged={(newPage) => setPage(newPage)}
      orgTeams={allOrgTeams}
      removeMember={removeMember}
      changeMemberAccessibility={changeMemberAccessibility}
      changeUserOrgTeams={changeUserOrgTeams}
      changeMemberRole={changeMemberRole}
      sendInvite={sendInvite}
      inviteError={inviteError}
      warningsMessage={
        isLimitedToAddContributors && (
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Box>
              {`You have exceeded your members quota (${maxContributors} members).`}
            </Box>
            <LinkButton
              sx={{ ml: 3 }}
              text="Upgrade Plan"
              size="small"
              type="primary"
              to={paths.closed.settingsPlans}
            />
          </Flex>
        )
      }
      isLimitedToAddContributors={isLimitedToAddContributors}
      invites={invites}
      isLoadingInvites={isLoadingInvites}
      removeInvite={removeInvite}
      isSSOConfigured={isSSOConfigured}
    />
  )
}

export default TeamMembersConnected
