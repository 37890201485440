import { RoleType } from '../role'

export const getEmailDomainFromUsername = (username: string) =>
  username.split('@')[1]

export type UserView = {
  orgTeams: string[]
  role: RoleType
  avatarUrl: string
  username: string
  pid: string
  firstName: string
  lastName: string
  canAccessAllAssets: boolean
}
