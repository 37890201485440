import {
  AssetCategory,
  AssetPageTab,
  AssetStats,
  AssetType,
  RoleType,
  toLongDisplayNumber,
} from '@spectral/types'
import { Button, Tabs } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from 'theme-ui'
import pick from 'lodash/pick'
import { DeleteOutlined } from '@ant-design/icons'
import IacList from '../../containers/iac-list'
import OpenSourceList from '../../containers/open-source-list'
import SecretsView from '../../containers/Secrets/secrets-view'
import SecretsSprawl from '../../containers/Secrets/secrets-sprawl'
import DiscoverIssuesView from '../../containers/Discover/discover-view'
import ScansHistory from '../../containers/scan-history'
import AssetHeader from './asset-header'
import theme from '../../common/theme'
import Restricted from '../../containers/role-based-render/restricted'
import DeleteAssetConfirmation from './delete-asset-confirmation/delete-asset-confirmation'

const { TabPane } = Tabs

const AssetPageTabs = styled(Tabs)`
  & .ant-tabs-content-holder {
    flex: 1;
    height: 0;
  }
  & .ant-tabs-content {
    height: 100%;
  }
  & .ant-tabs-tabpane {
    height: 100%;
  }
  & .ant-tabs-nav {
    margin-right: 40px;
  }
  & .ant-tabs-tab-disabled {
    cursor: default;
  }
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ShouldShowTabForCategoryAndKind = ({
  tab,
  kind,
  category,
}: {
  tab: AssetPageTab
  kind: AssetType
  category: AssetCategory
}) => {
  switch (tab) {
    case AssetPageTab.Iac:
    case AssetPageTab.OpenSource:
    case AssetPageTab.ScansHistory:
      return [AssetCategory.Code, AssetCategory.Host].includes(category)
    case AssetPageTab.DiscoverIssues:
      return (
        ![AssetType.Automation].includes(kind) &&
        category === AssetCategory.Code
      )
    case AssetPageTab.Secrets:
    case AssetPageTab.SecretsSprawl:
    default:
      return true
  }
}

const AssetPage = ({
  assetInfo,
  isAssetInfoLoaded,
  fetchAsset,
  clearCurrentAsset,
  onTabChanged,
  tab,
}) => {
  const [
    isDeleteAssetConfirmationShown,
    setIsDeleteAssetConfirmationShown,
  ] = useState(false)

  if (!assetInfo) {
    return <Box />
  }
  const {
    id,
    displayName,
    kind,
    category,
    source,
    displayStatus,
    isNotActive,
    lastScanDate,
    orgTeam,
    stats,
    uri,
  } = assetInfo
  const activeTab =
    tab ||
    (stats?.secrets > 0 && AssetPageTab.Secrets) ||
    (stats?.iac > 0 && AssetPageTab.Iac) ||
    (stats?.discover > 0 && AssetPageTab.DiscoverIssues) ||
    (stats && stats[AssetStats.OpenSource] > 0 && AssetPageTab.OpenSource) ||
    AssetPageTab.Secrets

  return (
    <Flex sx={{ flexDirection: 'column', height: '100%' }}>
      {isAssetInfoLoaded && (
        <Box
          sx={{
            mr: '40px',
            pb: 3,
            borderBottom: `solid 1px ${theme.stylebook.colors.controlBorder}`,
          }}
        >
          <AssetHeader
            id={id}
            displayName={displayName}
            kind={kind}
            source={source}
            uri={uri}
            displayStatus={displayStatus}
            isNotActive={isNotActive}
            lastScanDate={lastScanDate}
            orgTeam={orgTeam}
            stats={pick(stats, [
              AssetStats.Critical,
              AssetStats.High,
              AssetStats.Medium,
              AssetStats.Low,
              AssetStats.Informational,
              AssetStats.Ignore,
            ])}
          />
        </Box>
      )}
      <AssetPageTabs
        tabBarExtraContent={
          <Restricted roles={[RoleType.Owner, RoleType.Admin]}>
            <Button
              size="small"
              icon={<DeleteOutlined />}
              danger
              onClick={() => setIsDeleteAssetConfirmationShown(true)}
            >
              Delete Asset
            </Button>
            {isDeleteAssetConfirmationShown && (
              <DeleteAssetConfirmation
                selectedAssetId={decodeURIComponent(id)}
                assetDisplayName={displayName}
                clearCurrentAsset={clearCurrentAsset}
                handleConfirmationClose={() =>
                  setIsDeleteAssetConfirmationShown(false)
                }
              />
            )}
          </Restricted>
        }
        destroyInactiveTabPane
        onChange={(newTab) => onTabChanged(newTab)}
        activeKey={activeTab}
      >
        {ShouldShowTabForCategoryAndKind({
          tab: AssetPageTab.Secrets,
          category,
          kind,
        }) && (
          <TabPane
            tab={`Secrets (${toLongDisplayNumber(stats.secrets)})`}
            key={AssetPageTab.Secrets}
          >
            <SecretsView fetchAsset={fetchAsset} asset={assetInfo} />
          </TabPane>
        )}
        {ShouldShowTabForCategoryAndKind({
          tab: AssetPageTab.Iac,
          category,
          kind,
        }) && (
          <TabPane
            tab={`IaC (${toLongDisplayNumber(stats.iac)})`}
            key={AssetPageTab.Iac}
          >
            <IacList fetchAsset={fetchAsset} asset={assetInfo} />
          </TabPane>
        )}
        {ShouldShowTabForCategoryAndKind({
          tab: AssetPageTab.DiscoverIssues,
          category,
          kind,
        }) && (
          <TabPane
            tab={`CI/CD Hardening (${toLongDisplayNumber(stats.discover)})`}
            key={AssetPageTab.DiscoverIssues}
          >
            <DiscoverIssuesView fetchAsset={fetchAsset} asset={assetInfo} />
          </TabPane>
        )}
        {ShouldShowTabForCategoryAndKind({
          tab: AssetPageTab.OpenSource,
          category,
          kind,
        }) && (
          <TabPane
            tab={`Open Source (${toLongDisplayNumber(
              stats[AssetStats.OpenSource] || 0
            )})`}
            key={AssetPageTab.OpenSource}
          >
            <OpenSourceList fetchAsset={fetchAsset} asset={assetInfo} />
          </TabPane>
        )}
        <TabPane disabled tab="|" key="separator" />
        <TabPane tab="Sprawl" key={AssetPageTab.SecretsSprawl}>
          <SecretsSprawl asset={assetInfo} />
        </TabPane>
        {ShouldShowTabForCategoryAndKind({
          tab: AssetPageTab.ScansHistory,
          category,
          kind,
        }) && (
          <TabPane tab="Scans History" key={AssetPageTab.ScansHistory}>
            <ScansHistory />
          </TabPane>
        )}
      </AssetPageTabs>
    </Flex>
  )
}

export default AssetPage
