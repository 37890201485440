/** @jsx jsx */
import { Tooltip, Collapse, Descriptions, Tag } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { Box, Flex, jsx, Text } from 'theme-ui'
import { format, formatDistanceToNow } from 'date-fns'
import capitalize from 'lodash/capitalize'
import { dateFormat, toLongDisplayNumber } from '@spectral/types'
import isNil from 'lodash/isNil'
import { SeverityCountTag } from '../../../../components/tags/severity'
import ScanHistoryCollapse from '../../../../components/collapse/styled-collapse'
import track from '../../../../common/track/asset-page'
import EmptyAsset from '../../empty-asset'
import theme from '../../../../common/theme'
import Pagination from '../../../../components/pagination'

export const ScanHistoryItem = ({ insight }) => {
  const { issuesEffect, newIssuesCount, updatedAt } = insight
  return (
    <Box>
      <Flex
        sx={{
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Flex sx={{ minWidth: '180px' }}>
          <Tooltip
            title={
              <Box>
                <Box>Scan date:</Box>
                <Box>{format(new Date(updatedAt), dateFormat)}</Box>
              </Box>
            }
          >
            <Text sx={{ mr: 2 }} variant="ellipsis">
              {formatDistanceToNow(new Date(updatedAt))} ago
            </Text>
          </Tooltip>
        </Flex>
        <Flex sx={{ minWidth: '111px' }}>
          <Tooltip
            title={
              <Box>
                <Flex>
                  {newIssuesCount === 0
                    ? 'No new issues were found'
                    : Object.entries(issuesEffect.new).map(
                        ([severity, value]) => (
                          <Box
                            sx={{ marginRight: 1, marginBottom: 1 }}
                            key={`${severity}${value}`}
                          >
                            <SeverityCountTag
                              kind={severity}
                              count={value}
                              countOnly
                            />
                          </Box>
                        )
                      )}
                </Flex>
              </Box>
            }
          >
            <Text
              sx={{
                mr: 3,
                color:
                  newIssuesCount > 0
                    ? theme.stylebook.colors.red
                    : theme.stylebook.colors.gray[500],
              }}
            >
              New: {toLongDisplayNumber(newIssuesCount)}
            </Text>
          </Tooltip>
        </Flex>
        <Flex sx={{ minWidth: '130px' }}>
          <Text
            sx={{
              color:
                issuesEffect.resolved > 0
                  ? theme.stylebook.colors.green
                  : theme.stylebook.colors.gray[500],
            }}
          >
            Resolved: {toLongDisplayNumber(issuesEffect.resolved)}
          </Text>
        </Flex>
        {!isNil(issuesEffect.total) && (
          <Flex sx={{ minWidth: '120px' }}>
            <Text>Total issues: {toLongDisplayNumber(issuesEffect.total)}</Text>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export const ScanHistoryPanelContent = ({ scanContext, orgTeamName }) => {
  const {
    host,
    engines,
    scannerVersion,
    rulesSpec,
    botName,
    botVersion,
    assetVariant,
    validate,
  } = scanContext

  const rulesSpecs =
    rulesSpec &&
    Object.entries(rulesSpec).map(([rule, value]: [string, Array<string>]) => {
      return (
        !isEmpty(value) && (
          <Descriptions.Item
            label={<Text sx={{ fontWeight: 'bold' }}>{capitalize(rule)}:</Text>}
          >
            <Flex sx={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              {value.map((tag) => (
                <Tag>{tag}</Tag>
              ))}
            </Flex>
          </Descriptions.Item>
        )
      )
    })
  return (
    <Box>
      <Descriptions size="small" column={2} colon={false}>
        {!isEmpty(rulesSpecs) && rulesSpecs}
        <Descriptions.Item
          label={<Text sx={{ fontWeight: 'bold' }}>Engines:</Text>}
        >
          {engines.map((engine) => capitalize(engine)).join(', ')}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Text sx={{ fontWeight: 'bold' }}>Team:</Text>}
        >
          {orgTeamName}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Text sx={{ fontWeight: 'bold' }}>Scanner version:</Text>}
        >
          {scannerVersion}
        </Descriptions.Item>
        {host && (
          <Descriptions.Item
            label={<Text sx={{ fontWeight: 'bold' }}>Host:</Text>}
          >
            {host}
          </Descriptions.Item>
        )}
        {botName && (
          <Descriptions.Item
            label={<Text sx={{ fontWeight: 'bold' }}>Scanned by:</Text>}
          >
            {`${botName} ${botVersion ? `v${botVersion}` : ''}`}
          </Descriptions.Item>
        )}

        {assetVariant && (
          <Descriptions.Item
            label={<Text sx={{ fontWeight: 'bold' }}>Branch/tag:</Text>}
          >
            {assetVariant}
          </Descriptions.Item>
        )}
        {validate && (
          <Descriptions.Item
            label={<Text sx={{ fontWeight: 'bold' }}>Validation:</Text>}
          >
            Enabled
          </Descriptions.Item>
        )}
      </Descriptions>
    </Box>
  )
}

const ScanHistoryList = ({
  scanInsights,
  isLoading,
  onPageChanged,
  pagination,
  onChangeScanExpansion,
  expendedScan,
}) => {
  return isEmpty(scanInsights) ? (
    <Box sx={{ height: '100%', mt: 3 }}>
      <EmptyAsset isNotActive />
    </Box>
  ) : (
    <Flex sx={{ height: '100%', flexDirection: 'column', pr: '40px' }}>
      <ScanHistoryCollapse
        bordered={false}
        onChange={(updatedlyExpandedScan) => {
          track.scanHistoryItemClicked()
          onChangeScanExpansion(
            updatedlyExpandedScan ? [updatedlyExpandedScan] : null
          )
        }}
        activeKey={expendedScan}
        accordion
      >
        {scanInsights.map((insight) => {
          return (
            <Collapse.Panel
              header={<ScanHistoryItem insight={insight} />}
              key={insight.pid}
            >
              <ScanHistoryPanelContent
                scanContext={insight.scanContext}
                orgTeamName={insight.orgTeamName}
              />
            </Collapse.Panel>
          )
        })}
      </ScanHistoryCollapse>
      <Flex sx={{ pb: 2, pt: 2, justifyContent: 'end' }}>
        <Pagination
          current={pagination.page ? parseInt(pagination.page, 10) : 1}
          onChange={onPageChanged}
          total={pagination.total}
          pageSize={parseInt(pagination.pageSize, 10)}
          showSizeChanger
          disabled={isLoading}
        />
      </Flex>
    </Flex>
  )
}

export default ScanHistoryList
