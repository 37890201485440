/** @jsx jsx */
import { ReportIssue, RoleType } from '@spectral/types'
import { Checkbox, List } from 'antd'
import { isEmpty, size, map, includes } from 'lodash'
import { useCallback } from 'react'
import { Box, Flex, jsx } from 'theme-ui'
import styled from 'styled-components'
import IssuePath from '../../../../components/asset-page/issue-filepath/issue-filepath'
import IssueTags from '../../../../components/asset-page/issue-tags/issue-tags'
import SingleIssueAlert from '../../../../components/asset-page/single-issue/single-issue-alert'
import { Loading } from '../../../../components/loading'
import assetPageTracker from '../../../../common/track/asset-page'
import IgnorePopover from '../../../ignore-popover/view'
import DetectorInfo from '../../../detector-name/view'
import DetectorSeverity from '../../severity-change-dropdown'
import IssueActions from '../../issue-action/actions'
import EmptyAsset from '../../empty-asset'
import RegressionPopOver from '../../../regression-popover/view'
import ResolvePopOver from '../../../resolve-popover/view'
import IssueCollapse from '../../issue-collapse/issue-collapse'
import IssuesListActions from '../../issues-list-actions'
import Restricted from '../../../../containers/role-based-render/restricted'
import Pagination from '../../../../components/pagination'

const DiscoverItem = ({
  issue,
  isSelected,
  actionHandlers,
  onDiscoverIssueSelectionChanged,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  integrations,
}) => {
  const {
    link,
    detectorId,
    detectorName,
    detectorDescription,
    firstSeen,
    displaySeverity,
    originalSeverity,
    ignore: {
      isIgnored,
      ignoreType,
      comment,
      ignoreDate,
      actor,
      timeSnoozeOver,
    },
    metadata,
    status,
  } = issue
  const [
    handleIgnore,
    handleSnooze,
    handleAssigneeChange,
    handleAssigneeRemove,
    handleSearchAssignee,
    _handleSeverityChange,
    handleResolve,
    handleUnresolve,
    handleAssetIssuesSeverityChange,
  ] = actionHandlers

  const handleSeverityChange = useCallback(
    (severityChange) => {
      handleAssetIssuesSeverityChange(severityChange)
      onDiscoverIssueSelectionChanged([issue], false)
    },
    [handleAssetIssuesSeverityChange, issue, onDiscoverIssueSelectionChanged]
  )
  const originalCreatedAt = metadata?.originalCreatedAt
  const resolvedDate = metadata?.resolvedDate

  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Flex sx={{ mb: 2 }}>
          <Box mx={3} sx={{ minWidth: '16px' }}>
            <Restricted
              roles={[RoleType.Owner, RoleType.Admin, RoleType.Member]}
            >
              <Checkbox
                sx={{ mr: 3 }}
                checked={isSelected}
                onChange={(event) => {
                  onDiscoverIssueSelectionChanged([issue], event.target.checked)
                }}
              />
            </Restricted>

            {isIgnored && (
              <IgnorePopover
                ignoreType={ignoreType}
                ignoreDate={ignoreDate}
                comment={comment}
                actor={actor}
                timeSnoozeOver={timeSnoozeOver}
              />
            )}
            <RegressionPopOver
              originalCreatedAt={originalCreatedAt}
              status={status}
            />
            <ResolvePopOver resolvedDate={resolvedDate} status={status} />
          </Box>
          <Box mr={2}>
            <Flex mb={2}>
              <Box mr={2}>
                <DetectorSeverity
                  displaySeverity={displaySeverity}
                  originalSeverity={originalSeverity}
                  detectorId={detectorId}
                  detectorName={detectorName}
                  onSeverityChanged={handleSeverityChange}
                />
              </Box>
              <DetectorInfo
                isIgnored={isIgnored}
                link={link}
                detectorId={detectorId}
                detectorName={detectorName}
                onClick={() => assetPageTracker.playbookClicked(detectorId)}
              />
            </Flex>
            <Flex mb={2}>
              <IssuePath
                onClick={assetPageTracker.investigateDiscovertIssueClicked}
                issue={issue}
                maxWidth={500}
              />
            </Flex>
            <IssueTags issue={issue} integrations={integrations} />
          </Box>
        </Flex>
        <IssueActions
          title="Issue has been exposed for this long"
          firstSeen={firstSeen}
          isIgnored={isIgnored}
          handleIgnore={handleIgnore}
          handleSnooze={handleSnooze}
          issue={issue}
          handleResolve={handleResolve}
          handleUnresolve={handleUnresolve}
          assignableMembers={assignableMembers}
          isLoadingAssignableMembers={isLoadingAssignableMembers}
          isAssignableMembersLoaded={isAssignableMembersLoaded}
          handleAssigneeChange={handleAssigneeChange}
          handleAssigneeRemove={handleAssigneeRemove}
          handleSearchAssignee={handleSearchAssignee}
        />
      </Flex>
      <Box sx={{ pr: '20px', pl: '48px' }}>
        <IssueCollapse
          detectorDescription={detectorDescription}
          issue={issue}
        />
      </Box>
    </Box>
  )
}

const DiscoverList = styled(List)`
  .ant-list-items {
    width: 100%;
  }
`

const DiscoverIssuesListView = ({
  onPaginationChange,
  isRefreshing,
  discoverIssues,
  totalDiscoverIssues,
  actionHandlers,
  onDiscoverIssuesSelectionChanged,
  selectedDiscoverIssues,
  exportActions,
  currentQueryStringParams,
  clearQueryStringParam,
  isDownloadingExport,
  integrations,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  onActionIntegrationSubmit,
  isDataFiltered,
  isNotActiveAsset,
  setQueryStringParam,
  trackSort,
  sortOptions,
}) => {
  const { page, pageSize, issueId } = currentQueryStringParams
  const selectedIssuesIds = map(selectedDiscoverIssues, 'pid')
  const resetSingleIssue = () => {
    clearQueryStringParam('issueId')
  }

  return (
    <Flex sx={{ height: '100%', flexDirection: 'column', pr: '40px' }}>
      {!isRefreshing && !isEmpty(discoverIssues) && (
        <IssuesListActions
          actionHandlers={actionHandlers}
          selectedIssues={selectedDiscoverIssues}
          issues={discoverIssues}
          isDownloadingExport={isDownloadingExport}
          integrations={integrations}
          assignableMembers={assignableMembers}
          onActionIntegrationSubmit={onActionIntegrationSubmit}
          exportActions={exportActions}
          onSecretSelectionChanged={onDiscoverIssuesSelectionChanged}
          currentQueryStringParams={currentQueryStringParams}
          setQueryStringParam={setQueryStringParam}
          trackSort={trackSort}
          sortOptions={sortOptions}
          isLoadingAssignableMembers={isLoadingAssignableMembers}
          isAssignableMembersLoaded={isAssignableMembersLoaded}
        />
      )}
      {issueId && size(discoverIssues) === 1 && (
        <SingleIssueAlert
          alertType="info"
          alertText="You are viewing a single issue"
          onReset={resetSingleIssue}
          resetText="view all issues in this asset"
        />
      )}
      {issueId && size(discoverIssues) === 0 && (
        <SingleIssueAlert
          alertType="error"
          alertText="Requested issue does not exist"
          onReset={resetSingleIssue}
          resetText="view all issues in this asset"
        />
      )}
      {isRefreshing ? (
        <Box sx={{ height: '100%' }}>
          <Loading />
        </Box>
      ) : (
        <DiscoverList
          style={{ flex: 1, overflowY: 'auto' }}
          dataSource={discoverIssues}
          locale={{
            emptyText: !isRefreshing && (
              <EmptyAsset
                isNotActive={isNotActiveAsset}
                isNoDataShown={isDataFiltered}
              />
            ),
          }}
          renderItem={(discoverIssue: ReportIssue) => (
            <List.Item>
              <Box sx={{ width: '100%' }}>
                <DiscoverItem
                  issue={discoverIssue}
                  actionHandlers={actionHandlers}
                  isSelected={includes(selectedIssuesIds, discoverIssue.pid)}
                  onDiscoverIssueSelectionChanged={
                    onDiscoverIssuesSelectionChanged
                  }
                  assignableMembers={assignableMembers}
                  isLoadingAssignableMembers={isLoadingAssignableMembers}
                  isAssignableMembersLoaded={isAssignableMembersLoaded}
                  integrations={integrations}
                />
              </Box>
            </List.Item>
          )}
        />
      )}
      {!issueId && (
        <Flex sx={{ pb: 2, pt: 2, justifyContent: 'end' }}>
          <Pagination
            current={page ? parseInt(page, 10) : 1}
            onChange={onPaginationChange}
            total={totalDiscoverIssues}
            pageSize={parseInt(pageSize, 10)}
            showSizeChanger
            disabled={isRefreshing}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default DiscoverIssuesListView
