/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui'
import { Switch, Route, NavLink, Redirect, useLocation } from 'react-router-dom'
import { Menu, Badge, Tooltip } from 'antd'
import styled from 'styled-components'
import { RoleType } from '@spectral/types'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { Page } from '../../components/page'
import { paths, routes } from '../../routes'
import { select } from '../../redux/store'
import { MenuSkeleton } from '../../components/skeletons'
import { features } from '../../common/features'
import { isEmbedded } from '../../common/utils'

const settingsRestrictionModeAllowed = [
  paths.closed.settingsSCM,
  paths.closed.settingsDeleteAsset,
  paths.closed.remoteConfig,
  paths.closed.customRules,
  paths.closed.settingsProfile,
  paths.closed.settingsTeam,
  paths.closed.settingsIntegrations,
  paths.closed.settingsAssets,
  paths.closed.orgTeam,
]

const Item = styled(Menu.Item)`
  .ant-badge a,
  a {
    font-size: 14px;
    color: #888;
  }
  &.ant-menu-item-selected a,
  &.ant-menu-item-selected a:hover {
    color: #762aeb;
  }
  && {
    height: 24px;
    line-height: 24px;
  }
  &&.ant-menu-item-selected {
    background: none;
  }
  &&.ant-menu-item::after {
    border-width: 4px;
  }
`
const InactiveItem = styled(Menu.Item)`
  cursor: inherit;
`

export default () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const currentPlanType = useSelector(select.Plan.currentPlanType)
  const { role } = useSelector((state) => state.Auth.user)
  const isLoadingPlan = useSelector(select.Plan.isLoading)
  const showLoader = isLoadingPlan && !isEmbedded()
  const settingsBadges = useSelector(select.Settings.settingsBadges)
  const isFetchingVersions = useSelector(select.CustomRules.isFetchingVersions)

  const fetchCustomRules = async () => {
    if (!isFetchingVersions) {
      await dispatch.CustomRules.fetchCustomRulesVersions()
    }
  }
  useEffect(() => {
    dispatch.Teams.fetchSettings()
  }, [dispatch.Teams])

  useEffect(() => {
    if ([RoleType.Owner, RoleType.Admin].includes(role)) {
      fetchCustomRules()
    }
  }, [])

  const settingsRoute = routes.closed
    .find((r) => r.path === paths.closed.settings)
    .nested.filter((route) => {
      const isPlanRouteAllowed = !route.planRestricted?.includes(
        currentPlanType
      )
      const isOtherRouteAllowed = features.isRestrictionSettingsMode
        ? settingsRestrictionModeAllowed.includes(route.path)
        : true
      return isPlanRouteAllowed && isOtherRouteAllowed
    })

  const openRoutes = settingsRoute.filter((route) => !route.role)
  const adminRoutes = settingsRoute.filter(
    (route) => route.role === RoleType.Admin
  )
  const ownerRoutes = settingsRoute.filter(
    (route) => route.role === RoleType.Owner
  )

  const renderRouteLabel = (route) => {
    if (settingsBadges[route.path]?.showBadge) {
      return (
        <Flex sx={{ justifyContent: 'space-between' }}>
          <NavLink to={route.path}>{route.label}</NavLink>
          <Tooltip title={settingsBadges[route.path]?.tooltip}>
            <Badge size="small" count="!" style={{ marginTop: '4px' }} />
          </Tooltip>
        </Flex>
      )
    }
    return <NavLink to={route.path}>{route.label}</NavLink>
  }

  let routesToDisplay = []
  switch (role) {
    case RoleType.ReadOnly:
    case RoleType.Member:
      routesToDisplay = [...openRoutes]
      break
    case RoleType.Admin:
      routesToDisplay = [...openRoutes, ...adminRoutes]
      break
    case RoleType.Owner:
      routesToDisplay = [...openRoutes, ...adminRoutes, ...ownerRoutes]
      break
  }

  return (
    <Page name="Settings" title="Settings">
      <span className="e2e-test-settings-page-title" />
      <Flex>
        <Box sx={{ minWidth: '200px' }}>
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            sx={{
              borderRight: '1px solid #e6e6e6',
              position: 'sticky',
              top: '0',
              overflow: 'scroll',
              fontSize: '14px',
              height: '100vh',
              pt: '40px',
            }}
          >
            <InactiveItem>
              <Text sx={{ fontSize: '14px', fontWeight: 600 }}>Settings</Text>
            </InactiveItem>

            {showLoader && <MenuSkeleton lines={4} />}
            {!showLoader &&
              routesToDisplay.map((route) => (
                <Item key={route.path}>{renderRouteLabel(route)}</Item>
              ))}
          </Menu>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Switch>
            <Route exact path={paths.closed.settings}>
              <Redirect to={settingsRoute[0].path} />
            </Route>
            {routesToDisplay.map((route) => (
              <Route exact path={route.path} key={route.path}>
                <route.component />
              </Route>
            ))}
          </Switch>
        </Box>
      </Flex>
    </Page>
  )
}
