/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui'
import { Tooltip } from 'antd'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import { toShortDisplayNumber } from '@spectral/types'
import getSeverityConfig from './severity-config'

export const SeverityCountTag = ({
  className = undefined,
  kind,
  count,
  countOnly = false,
}) => {
  const config = getSeverityConfig(kind, count)
  const capitalizedKind = capitalize(kind)
  return (
    <Flex
      className={className}
      sx={{
        fontSize: '12px',
        color: '#11336a',
      }}
    >
      {!countOnly && (
        <Box
          sx={{
            border: `1px solid ${config.strongColor}`,
            borderRadius: countOnly ? '4px' : '4px 0px 0px 4px',
            borderRight: 'none',
            color: config.strongColor,
            backgroundColor: config.weakColor,
            flex: 1,
            padding: '1px 8px',
          }}
        >
          {capitalizedKind}
        </Box>
      )}
      <Tooltip title={startCase(config.displayText.toLowerCase())}>
        <Flex
          sx={{
            backgroundColor: config.strongColor,
            color: '#ffffff',
            padding: '2px 8px',
            borderRadius: countOnly ? '4px' : '0px 4px 4px 0',
            width: '45px',
            justifyContent: 'center',
          }}
        >
          {toShortDisplayNumber(count)}
        </Flex>
      </Tooltip>
    </Flex>
  )
}
