/** @jsx jsx */
import {
  AssetCategory,
  AssetType,
  kindToCategory,
  ReportIssue,
  RoleType,
  ValidationResult,
} from '@spectral/types'
import styled from 'styled-components'
import { Checkbox, List, Tag, Tooltip } from 'antd'
import { isEmpty, map, size, includes } from 'lodash'
import { Fragment, useCallback } from 'react'
import { Box, Flex, jsx, Text } from 'theme-ui'
import IssuePath from '../../../../components/asset-page/issue-filepath/issue-filepath'
import IssueTags from '../../../../components/asset-page/issue-tags/issue-tags'
import SingleIssueAlert from '../../../../components/asset-page/single-issue/single-issue-alert'
import { Loading } from '../../../../components/loading'
import assetPageTracker from '../../../../common/track/asset-page'
import IgnorePopover from '../../../ignore-popover/view'
import RegressionPopOver from '../../../regression-popover/view'
import DetectorInfo from '../../../detector-name/view'
import EmptyAsset from '../../empty-asset'
import DetectorSeverity from '../../severity-change-dropdown'
import IssueActions from '../../issue-action/actions'
import ResolvePopOver from '../../../resolve-popover/view'
import HostIssuePath from '../../../../components/asset-page/issue-filepath/host-issue-filepath'
import IssueCollapse from '../../issue-collapse/issue-collapse'
import IssuesListActions from '../../issues-list-actions'
import { DateFormat } from '../../../../components/formatters'
import Restricted from '../../../../containers/role-based-render/restricted'
import Pagination from '../../../../components/pagination'

const SecretItem = ({
  issue,
  isSelected,
  actionHandlers,
  onSecretSelectionChanged,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  integrations,
}) => {
  const {
    link,
    detectorId,
    detectorName,
    detectorDescription,
    content,
    asset,
    uri,
    firstSeen,
    displaySeverity,
    originalSeverity,
    ignore: {
      isIgnored,
      ignoreType,
      comment,
      ignoreDate,
      actor,
      timeSnoozeOver,
    },
    metadata,
    status,
  } = issue
  const hint = metadata?.hint
  const originalCreatedAt = metadata?.originalCreatedAt
  const resolvedDate = metadata?.resolvedDate
  const [
    handleIgnore,
    handleSnooze,
    handleAssigneeChange,
    handleAssigneeRemove,
    handleSearchAssignee,
    _handleSeverityChange,
    handleResolve,
    handleUnresolve,
    handleAssetIssuesSeverityChange,
  ] = actionHandlers

  const handleSeverityChange = useCallback(
    (severityChange) => {
      handleAssetIssuesSeverityChange(severityChange)
      onSecretSelectionChanged([issue], false)
    },
    [handleAssetIssuesSeverityChange, issue, onSecretSelectionChanged]
  )

  const assetCategory = kindToCategory(asset.type)

  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Flex sx={{ mb: 2 }}>
          <Box mx={3} sx={{ minWidth: '16px' }}>
            <Restricted
              roles={[RoleType.Owner, RoleType.Admin, RoleType.Member]}
            >
              <Checkbox
                checked={isSelected}
                onChange={(event) => {
                  onSecretSelectionChanged([issue], event.target.checked)
                }}
              />
            </Restricted>
            {isIgnored && (
              <IgnorePopover
                ignoreType={ignoreType}
                ignoreDate={ignoreDate}
                comment={comment}
                actor={actor}
                timeSnoozeOver={timeSnoozeOver}
              />
            )}
            <RegressionPopOver
              originalCreatedAt={originalCreatedAt}
              status={status}
            />
            <ResolvePopOver resolvedDate={resolvedDate} status={status} />
          </Box>
          <Box mr={2}>
            <Flex mb={2}>
              <DetectorSeverity
                displaySeverity={displaySeverity}
                originalSeverity={originalSeverity}
                detectorId={detectorId}
                detectorName={detectorName}
                onSeverityChanged={handleSeverityChange}
              />
              <DetectorInfo
                isIgnored={isIgnored}
                link={link}
                detectorId={detectorId}
                detectorName={detectorName}
                onClick={() => assetPageTracker.playbookClicked(detectorId)}
              />
            </Flex>
            <Flex mb={2}>
              {![AssetType.Slack, AssetType.Host].includes(asset.type) && (
                <IssuePath
                  onClick={assetPageTracker.investigateClicked}
                  issue={issue}
                />
              )}
              {asset.type === AssetType.Host && <HostIssuePath issue={issue} />}
              {asset.type === AssetType.Slack && (
                <a href={uri} rel="noopener noreferrer" target="_blank">
                  see message
                </a>
              )}
              {assetCategory === AssetCategory.Code && (
                <Text sx={{ color: 'gray.500', mr: 1, ml: 1 }}>
                  ({content})
                </Text>
              )}
              {[AssetCategory.Productivity].includes(assetCategory) && hint && (
                <Text sx={{ color: 'gray.500', mr: 1, ml: 1 }}>
                  (Hint: {hint})
                </Text>
              )}
            </Flex>

            <IssueTags issue={issue} integrations={integrations} />
          </Box>
        </Flex>
        <IssueActions
          title="Secret has been exposed for this long"
          firstSeen={firstSeen}
          isIgnored={isIgnored}
          handleIgnore={handleIgnore}
          handleSnooze={handleSnooze}
          issue={issue}
          handleResolve={handleResolve}
          handleUnresolve={handleUnresolve}
          assignableMembers={assignableMembers}
          isLoadingAssignableMembers={isLoadingAssignableMembers}
          isAssignableMembersLoaded={isAssignableMembersLoaded}
          handleAssigneeChange={handleAssigneeChange}
          handleAssigneeRemove={handleAssigneeRemove}
          handleSearchAssignee={handleSearchAssignee}
        />
      </Flex>
      <Box sx={{ pr: '20px', pl: '48px' }}>
        {issue.validation?.result === ValidationResult.Valid && (
          <Box mb="15px">
            <Tooltip
              title={() => {
                return (
                  <Fragment>
                    Validation check ran at{' '}
                    <DateFormat date={issue.validation?.lastValidation} />
                  </Fragment>
                )
              }}
            >
              <Tag color="#e67e83" style={{ borderRadius: '25px' }}>
                Valid Key
              </Tag>
            </Tooltip>
          </Box>
        )}
        {issue.validation?.result === ValidationResult.Invalid && (
          <Box mb="15px">
            <Tooltip
              title={() => {
                return (
                  <Fragment>
                    Validation check ran at{' '}
                    <DateFormat date={issue.validation?.lastValidation} />
                  </Fragment>
                )
              }}
            >
              <Tag color="#b3b3cc" style={{ borderRadius: '25px' }}>
                Invalid Key
              </Tag>
            </Tooltip>
          </Box>
        )}
        <IssueCollapse
          detectorDescription={detectorDescription}
          issue={issue}
        />
      </Box>
    </Box>
  )
}

const SecretsList = styled(List)`
  .ant-list-items {
    width: 100%;
  }
`

const SecretsListView = ({
  onPaginationChange,
  isRefreshing,
  secrets,
  totalSecrets,
  actionHandlers,
  onSecretSelectionChanged,
  selectedSecrets,
  exportActions,
  currentQueryStringParams,
  clearQueryStringParam,
  isDownloadingExport,
  integrations,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  onActionIntegrationSubmit,
  isNotActiveAsset,
  isDataFiltered,
  setQueryStringParam,
  trackSort,
  sortOptions,
}) => {
  const { assetId, page, pageSize, issueId } = currentQueryStringParams
  const [_handleSeverityChange] = actionHandlers
  const selectedIssuesIds = map(selectedSecrets, 'pid')

  const resetSingleIssue = () => {
    clearQueryStringParam('issueId')
  }

  return (
    <Flex sx={{ height: '100%', flexDirection: 'column', pr: '40px' }}>
      {!isRefreshing && !isEmpty(secrets) && (
        <IssuesListActions
          actionHandlers={actionHandlers}
          selectedIssues={selectedSecrets}
          issues={secrets}
          isDownloadingExport={isDownloadingExport}
          integrations={integrations}
          assignableMembers={assignableMembers}
          onActionIntegrationSubmit={onActionIntegrationSubmit}
          exportActions={exportActions}
          onSecretSelectionChanged={onSecretSelectionChanged}
          currentQueryStringParams={currentQueryStringParams}
          setQueryStringParam={setQueryStringParam}
          trackSort={trackSort}
          sortOptions={sortOptions}
          isLoadingAssignableMembers={isLoadingAssignableMembers}
          isAssignableMembersLoaded={isAssignableMembersLoaded}
        />
      )}
      {issueId && size(secrets) === 1 && (
        <SingleIssueAlert
          alertType="info"
          alertText="You are viewing a single issue"
          onReset={resetSingleIssue}
          resetText="view all issues in this asset"
        />
      )}
      {issueId && size(secrets) === 0 && (
        <SingleIssueAlert
          alertType="error"
          alertText="Requested issue does not exist"
          onReset={resetSingleIssue}
          resetText="view all issues in this asset"
        />
      )}
      {isRefreshing ? (
        <Box sx={{ height: '100%' }}>
          <Loading />
        </Box>
      ) : (
        <SecretsList
          style={{ flex: 1, overflowY: 'auto' }}
          dataSource={secrets}
          locale={{
            emptyText: !isRefreshing && (
              <EmptyAsset
                isNotActive={isNotActiveAsset}
                isNoDataShown={isDataFiltered}
              />
            ),
          }}
          renderItem={(secret: ReportIssue) => (
            <List.Item>
              <Box sx={{ width: '100%' }}>
                <span className="e2e-test-secret-row" />
                <SecretItem
                  issue={secret}
                  actionHandlers={actionHandlers}
                  isSelected={includes(selectedIssuesIds, secret.pid)}
                  onSecretSelectionChanged={onSecretSelectionChanged}
                  assignableMembers={assignableMembers}
                  isLoadingAssignableMembers={isLoadingAssignableMembers}
                  isAssignableMembersLoaded={isAssignableMembersLoaded}
                  integrations={integrations}
                />
              </Box>
            </List.Item>
          )}
        />
      )}
      {!issueId && (
        <Flex sx={{ pb: 2, pt: 2, justifyContent: 'end' }}>
          <Pagination
            current={page ? parseInt(page, 10) : 1}
            onChange={onPaginationChange}
            total={totalSecrets}
            pageSize={parseInt(pageSize, 10)}
            showSizeChanger
            disabled={isRefreshing}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default SecretsListView
