/** @jsx jsx */
import { useState, useCallback, useEffect, useMemo } from 'react'
import { jsx, Box, Flex } from 'theme-ui'
import { Select, Button, Tooltip, notification } from 'antd'
import AceEditor from 'react-ace'
import detectors from 'detectorHelp/detectors-help.json'
import yaml from 'js-yaml'
import pull from 'lodash/pull'
import every from 'lodash/every'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import {
  AssetType,
  ConfigType,
  FallbackMode,
  HardeningFeatures,
  MatchIgnoresConfigValues,
} from '@spectral/types'
import {
  CloudDownloadOutlined,
  DownloadOutlined,
  PlusOutlined,
  RollbackOutlined,
} from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { DOCS_URL } from '../../../utils/constants'
import {
  ContentPanel,
  ContentPanelHeader,
  NestedRadioButtonFieldSection,
  PanelForm,
  RadioButtonFieldSection,
} from '../../../components/panels/content'
import 'ace-builds/src-noconflict/mode-yaml'
import 'ace-builds/src-noconflict/theme-solarized_dark'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-min-noconflict/ext-searchbox'
import { saveConfigYamlFile } from '../../../utils/files'
import RemoteConfigurationSchema from './remote-config-schema'
import UpsertIgnoreModal from './modals/add-ignore-modal'
import DeleteIgnoreModal from './modals/delete-ignore-modal'
import ResetConfigurationModal from './modals/reset-config-modal'
import IgnoresTable from './ignores-table'
import TagsInput from './tags-input/tags-input'
import ConfigurationTypesMessageBox from './message-boxes/configuration-types-message-box'
import { PageTitle } from '../../../components/page'
import AssetTypeIcon from '../../../components/asset-type-icon/AssetTypeIcon'
import { showErrorNotification } from '../../../common/notifications'
import RulesInput from './rules-input/rules-input'

const { Option } = Select

const unsupportedRegexFeatures = ['(?i)']

const hardeningValues = {
  Allow: false,
  Restrict: true,
}

const fallbackValues = {
  WarnAndProceed: {
    label: 'Warn and proceed',
    value: FallbackMode.WarnAndProceed,
  },
  Strict: {
    label: 'Strict',
    value: FallbackMode.Strict,
  },
}

const buildProjectsConfig = ({
  include: { tags: tagsToInclude, ids: rulesToInclude },
  exclude: { tags: tagsToExclude, ids: rulesToExclude },
}) => {
  return {
    sample: {
      project: {
        name: 'sample',
      },
      input: [
        {
          local: '.',
          name: 'sources',
        },
      ],
      rules: {
        include: {
          tags: tagsToInclude,
          ids: rulesToInclude,
        },
        exclude: {
          tags: tagsToExclude,
          ids: rulesToExclude,
        },
      },
    },
  }
}

const formatConfigurations = (configurations) => {
  return Object.keys(configurations).map((assetType) => {
    return {
      id: assetType,
      config: configurations[assetType],
    }
  })
}

const RemoteConfiguration = ({
  onSave,
  onReset,
  configurationsData,
  isSavingAgentConfig,
  saveConfigurationError,
  scanConfigurationSuccess,
  triggerMixPanelConfigurationSavedEvent,
  triggerMixPanelShowFullYamlEvent,
  triggerMixPanelTagsChangedEvent,
  triggerMixPanelRemoveIgnoreEvent,
  triggerMixPanelAddNewIgnoreEvent,
  triggerMixPanelUpdateIgnoreEvent,
  triggerMixPanelOverridesChangedEvent,
  canUserEdit = false,
}) => {
  const isConfigurationEditable = !isSavingAgentConfig && canUserEdit
  const location = useLocation()
  const initialAssetType = location?.state?.selectedAssetType || AssetType.Git
  const config = configurationsData[initialAssetType]
  const [previewMode, setPreviewMode] = useState(true)
  const [showResetConfigModal, setShowResetConfigModal] = useState(false)
  const [selectedAssetType, setSelectedAssetType] = useState(initialAssetType)
  const [addNewIgnoreProcess, setAddNewIgnoreProcess] = useState(false)
  const [ignoreIndexToDelete, setIgnoreIndexToDelete] = useState(-1)
  const [ignoreIndexToEdit, setIgnoreIndexToEdit] = useState(-1)
  const [selectedConfig, setSelectedConfig] = useState(config)

  const hardeningFlags = [
    {
      name: HardeningFeatures.Ok,
      flag: '(--ok)',
      label: 'OK',
      description:
        'Ignore any discovered issues and pass the scan (exit code 0 for all cases)',
    },
    {
      name: HardeningFeatures.ExcludeTags,
      flag: '(--exclude-tags, spectral.yaml)',
      label: 'Exclude Tags',
      description: 'Exclude detectors by tags',
    },
    {
      name: HardeningFeatures.Exclude,
      flag: '(--exclude, spectral.yaml)',
      label: 'Exclude',
      description: 'Exclude detectors list',
    },
    {
      name: HardeningFeatures.Include,
      flag: '(--include, spectral.yaml)',
      label: 'Include',
      description: 'Include detectors list',
    },
    {
      name: HardeningFeatures.MatchIgnores,
      label: 'Ignores',
      description: 'Ignores set in spectral.yaml and inline ignores',
      onChangeAction: async (action, subFlags, flag) => {
        const newValue = Object.values(subFlags).reduce(
          (acc, subFlag: { name: string }) => {
            acc[subFlag.name] = action
            return acc
          },
          {}
        )
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await saveOverridesValues({
          matchIgnoresConfig: newValue,
          [flag.name]: action,
        })
      },
      subFlags: {
        prefix: HardeningFeatures.MatchIgnoresConfig,
        values: [
          {
            name: MatchIgnoresConfigValues.Text,
            label: 'Text',
            description: 'Ignore findings containing given text',
          },
          {
            name: MatchIgnoresConfigValues.LineText,
            label: 'Line Text',
            description: 'Ignore findings with given text as the whole line',
          },
          {
            name: MatchIgnoresConfigValues.RuleId,
            label: 'Rule Id',
            description: 'Ignore findings by rule id',
          },
          {
            name: MatchIgnoresConfigValues.RuleName,
            label: 'Rule Name',
            description: 'Ignore findings by rule name',
          },
          {
            name: MatchIgnoresConfigValues.Path,
            label: 'Path',
            description: 'Ignore findings by file path pattern',
          },
          {
            name: MatchIgnoresConfigValues.FingerPrint,
            label: 'Fingerprint',
            description: 'Ignore findings by fingerprint',
          },
        ],
      },
    },
    {
      name: HardeningFeatures.FailOnError,
      flag: '(--fail-on-error)',
      label: 'Fail On Error',
      description:
        'Fail with non-zero exit code just on high & critical severity matches',
    },
    {
      name: HardeningFeatures.FailOnCritical,
      flag: '(--fail-on-critical)',
      label: 'Fail On Critical',
      description:
        'Fail with non-zero exit code just on critical severity matches',
    },
    {
      name: HardeningFeatures.IgnoreRemoteConfig,
      flag: '(--ignore-remote-config)',
      label: 'Ignore Remote Config',
      description: 'Ignore remote config that has been set in SpectralOps',
    },
    {
      name: HardeningFeatures.Since,
      flag: '(--since)',
      label: 'Since',
      description:
        'Scan only files that have been changed since the provided date',
    },
    {
      name: HardeningFeatures.MaxSize,
      flag: '(--maxsize)',
      label: 'Max Size',
      description: 'Max file size to include in the scan',
    },
    {
      name: HardeningFeatures.ShowMatch,
      flag: '(--show-match, SPECTRAL_SHOW_MATCH=1)',
      label: 'Show Match',
      description: 'Show the actual match that was captured',
    },
    {
      name: HardeningFeatures.SendLocalIgnores,
      label: 'Hide Local Ignores',
      description:
        'When allow, ignores from spectral.yaml or inline code, without categorization, will not be sent to the dashboard.',
    },
  ]

  const rulesFromConfig = useMemo(() => {
    const projectsKeys = Object.keys(selectedConfig?.projects ?? {})
    if (projectsKeys.length === 0) {
      return { include: { ids: [], tags: [] }, exclude: { ids: [], tags: [] } }
    }

    const firstProjectRules = selectedConfig?.projects[projectsKeys[0]]?.rules

    return {
      include: {
        ids: firstProjectRules?.include?.ids ?? [],
        tags: firstProjectRules?.include?.tags ?? [],
      },
      exclude: {
        ids: firstProjectRules?.exclude?.ids ?? [],
        tags: firstProjectRules?.exclude?.tags ?? [],
      },
    }
  }, [selectedConfig])

  const allTags = useMemo(() => {
    const pinnedTags = ['audit', 'base', 'iac']

    const isTagUsable = (tag) => !tag.includes('/')
    const isPinnedTag = (tag) => pinnedTags.includes(tag)

    const tags = [
      // @ts-ignore
      ...new Set(
        Object.values(detectors)
          .map((object) => object.tags)
          .flat()
      ),
    ].filter((tag) => isTagUsable(tag) && !isPinnedTag(tag))

    tags.unshift(...pinnedTags)

    return tags
      .filter(
        (tag) =>
          !rulesFromConfig.exclude.tags.includes(tag) &&
          !rulesFromConfig.include.tags.includes(tag)
      )
      .map((tag) => ({ label: tag, value: tag }))
  }, [rulesFromConfig])

  const handleAssetTypeChange = useCallback(
    (newAssetType) => {
      setPreviewMode(true)
      setSelectedAssetType(newAssetType)
      setSelectedConfig(configurationsData[newAssetType])
    },
    [configurationsData]
  )

  useEffect(() => {
    setSelectedConfig(configurationsData[selectedAssetType])
  }, [configurationsData, selectedAssetType])

  useEffect(() => {
    if (scanConfigurationSuccess) {
      notification.success({
        message: 'Saved successfully',
      })
    }
  }, [scanConfigurationSuccess])

  const validateConfigurationHasValue = () => {
    const yamlConfig = yaml.dump(selectedConfig)
    if (!yamlConfig.trim()) {
      triggerMixPanelConfigurationSavedEvent(
        selectedAssetType,
        'error',
        'EMPTY_YAML'
      )
      throw new Error('Configuration is required and must have a value.')
    }
  }

  const resetConfiguration = async (configurationType: ConfigType) => {
    await onReset(configurationType, selectedAssetType)
    notification.success({
      message: 'Saved successfully',
    })

    triggerMixPanelConfigurationSavedEvent(selectedAssetType, 'success')
  }

  const saveConfiguration = async (configToSave) => {
    try {
      validateConfigurationHasValue()
      let jsonConfig = null
      try {
        jsonConfig = configToSave
      } catch (ex) {
        triggerMixPanelConfigurationSavedEvent(
          selectedAssetType,
          'error',
          'INVALID_YAML'
        )
        return showErrorNotification('YAML structure error', ex.message)
      }

      try {
        RemoteConfigurationSchema.validateSync(jsonConfig)
      } catch (error) {
        triggerMixPanelConfigurationSavedEvent(
          selectedAssetType,
          'error',
          'WRONG_CONFIGURATION_SCHEMA'
        )
        throw error
      }

      const newConfigurations = {
        ...configurationsData,
        [selectedAssetType]: jsonConfig,
      }

      const formattedConfigurations = formatConfigurations(newConfigurations)
      await onSave(formattedConfigurations, selectedAssetType)

      triggerMixPanelConfigurationSavedEvent(selectedAssetType, 'success')
    } catch (ex) {
      return showErrorNotification('Wrong configuration setup', ex.message)
    }
  }

  const applyDefaultConfiguration = async () => {
    await resetConfiguration(ConfigType.REMOTE_CONFIG)
    setShowResetConfigModal(false)
  }

  const sanitizeIgnore = (ignore) => {
    Object.keys(ignore).forEach((key) => {
      if (ignore[key] == null || ignore[key] === '') {
        delete ignore[key]
      }
    })
  }

  const validateIgnoreRegex = (ignore) => {
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const [, value] of Object.entries(ignore)) {
        let expression = value as string
        unsupportedRegexFeatures.forEach((feature: string) => {
          expression = expression.replace(feature, '')
        })
        // eslint-disable-next-line no-new
        new RegExp(expression)
      }
    } catch {
      showErrorNotification(
        'Wrong configuration setup',
        'Please insert valid regular expression'
      )
      throw new Error('Please insert valid regular expression')
    }
  }

  const removeIgnore = async () => {
    const currentSelectedConfig = { ...selectedConfig }
    currentSelectedConfig.match_ignores.ignores.splice(ignoreIndexToDelete, 1)

    setSelectedConfig(currentSelectedConfig)
    await saveConfiguration(currentSelectedConfig)
    triggerMixPanelRemoveIgnoreEvent(selectedAssetType)
  }

  const addNewIgnore = async (newIgnore) => {
    const currentSelectedConfig = { ...selectedConfig }
    sanitizeIgnore(newIgnore)
    validateIgnoreRegex(newIgnore)

    currentSelectedConfig.match_ignores.ignores.push(newIgnore)

    setSelectedConfig(currentSelectedConfig)
    await saveConfiguration(currentSelectedConfig)

    setAddNewIgnoreProcess(false)
    triggerMixPanelAddNewIgnoreEvent(selectedAssetType)
  }

  const updateIgnore = async (ignoreToUpdate) => {
    const currentSelectedConfig = { ...selectedConfig }
    sanitizeIgnore(ignoreToUpdate)
    validateIgnoreRegex(ignoreToUpdate)

    currentSelectedConfig.match_ignores.ignores[
      ignoreIndexToEdit
    ] = ignoreToUpdate

    setIgnoreIndexToEdit(-1)

    setSelectedConfig(currentSelectedConfig)
    await saveConfiguration(currentSelectedConfig)
    triggerMixPanelUpdateIgnoreEvent(selectedAssetType)
  }

  const saveConfigToSpectralYaml = () => {
    try {
      validateConfigurationHasValue()
      let jsonConfig = null
      try {
        jsonConfig = selectedConfig
      } catch (ex) {
        return showErrorNotification('YAML structure error', ex.message)
      }

      RemoteConfigurationSchema.validateSync(jsonConfig)
      const yamlConfig = yaml.dump(selectedConfig)

      return saveConfigYamlFile(yamlConfig)
    } catch (ex) {
      return showErrorNotification('Wrong configuration setup', ex.message)
    }
  }

  const onEditClick = (recordIndex: number) => {
    setIgnoreIndexToEdit(recordIndex)
  }

  const currentIgnoresDataSource = useCallback(() => {
    return selectedConfig.match_ignores.ignores.map((ignore, index) => {
      return { ...ignore, index }
    })
  }, [selectedConfig])

  const upsertIgnore = (ignoreData) => {
    if (ignoreIndexToEdit > -1) {
      return updateIgnore(ignoreData)
    }
    return addNewIgnore(ignoreData)
  }

  const getConfigWithoutOverrides = () => {
    return yaml.dump(omit(selectedConfig, 'overrides'))
  }

  const saveOverridesValues = async (newValues) => {
    newValues.matchIgnores = every(newValues.matchIgnoresConfig, Boolean)

    const newConfig = {
      ...selectedConfig,
      overrides: {
        ...selectedConfig.overrides,
        ...newValues,
      },
    }

    setSelectedConfig(newConfig)
    await saveConfiguration(newConfig)
    triggerMixPanelOverridesChangedEvent(selectedAssetType, newValues)
  }

  const saveFallbackMode = async (newValues) => {
    const newConfig = {
      ...selectedConfig,
      fallback: newValues,
    }
    setSelectedConfig(newConfig)
    await saveConfiguration(newConfig)
  }

  return (
    <Box sx={{ p: '40px' }}>
      <PageTitle>Scan Configuration</PageTitle>
      <Box sx={{ mb: '40px' }}>
        Create configuration to apply on your future assets scans, see our{' '}
        <a
          href={`${DOCS_URL}configuration#spectralyaml`}
          target="_blank"
          rel="noopener noreferrer"
        >
          docs
        </a>{' '}
        for more information on Spectral configuration
      </Box>

      <Box sx={{ mt: '30px', mb: '50px', position: 'relative' }}>
        <ContentPanel>
          <ContentPanelHeader>
            Choose asset type to apply configuration on
          </ContentPanelHeader>

          <Box sx={{ p: '16px' }}>
            <Select
              disabled={isSavingAgentConfig}
              showSearch
              placeholder="Search to select asset type"
              style={{ width: '100%' }}
              defaultValue={location?.state?.selectedAssetType || AssetType.Git}
              onChange={handleAssetTypeChange}
            >
              {pull(Object.values(AssetType)).map((type) => {
                return (
                  <Option key={`assettype_option_${type}`} value={type}>
                    <Box
                      sx={{
                        display: 'flex',
                        'img &': { width: '16px', height: '16px' },
                      }}
                    >
                      <Box sx={{ marginRight: '8px', marginTop: '-1px' }}>
                        <AssetTypeIcon
                          width="16px"
                          minWidth="16px"
                          height="16px"
                          minHeight="16px"
                          type={type}
                        />
                      </Box>
                      {capitalize(type)}
                    </Box>
                  </Option>
                )
              })}
            </Select>
          </Box>
        </ContentPanel>
      </Box>

      <Box sx={{ mt: '40px', position: 'relative' }}>
        <ContentPanel>
          <ContentPanelHeader>
            {`Setup match ignores for ${selectedAssetType} scans`}{' '}
            <Box sx={{ position: 'absolute', right: '15px' }}>
              <Button
                size="small"
                icon={<PlusOutlined />}
                onClick={() => setAddNewIgnoreProcess(true)}
                disabled={!isConfigurationEditable}
              >
                New Ignore
              </Button>
            </Box>
          </ContentPanelHeader>
          <IgnoresTable
            canUserEdit={isConfigurationEditable}
            onDelete={(recordIndex) => {
              setIgnoreIndexToDelete(recordIndex)
            }}
            ignores={currentIgnoresDataSource()}
            onEdit={onEditClick}
          />
        </ContentPanel>
      </Box>

      {[AssetType.Git, AssetType.Host].includes(selectedAssetType) && (
        <TagsInput
          allTags={allTags}
          disabled={!isConfigurationEditable}
          currentIncludeTags={rulesFromConfig.include.tags}
          currentExcludeTags={rulesFromConfig.exclude.tags}
          selectedAssetType={selectedAssetType}
          onSubmit={async (values) => {
            delete selectedConfig.projects

            const projectsConfigUpdate = {
              projects: buildProjectsConfig({
                include: {
                  tags: values.include,
                  ids: rulesFromConfig.include.ids,
                },
                exclude: {
                  tags: values.exclude,
                  ids: rulesFromConfig.exclude.ids,
                },
              }),
            }
            const newConfig = {
              ...selectedConfig,
              ...(isEmpty(projectsConfigUpdate.projects)
                ? {}
                : projectsConfigUpdate),
            }
            setSelectedConfig(newConfig)
            await saveConfiguration(newConfig)
            triggerMixPanelTagsChangedEvent(selectedAssetType)
          }}
        />
      )}
      {[
        AssetType.Git,
        AssetType.Host,
        AssetType.Automation,
        AssetType.Jira,
        AssetType.Confluence,
      ].includes(selectedAssetType) && (
        <RulesInput
          disabled={!isConfigurationEditable}
          saveConfigurationError={saveConfigurationError}
          currentIncludeRules={rulesFromConfig.include.ids}
          currentExcludeRules={rulesFromConfig.exclude.ids}
          selectedAssetType={selectedAssetType}
          onSubmit={async (values) => {
            delete selectedConfig.projects

            const projectsConfigUpdate = {
              projects: buildProjectsConfig({
                include: {
                  tags: rulesFromConfig.include.tags,
                  ids: values.include,
                },
                exclude: {
                  tags: rulesFromConfig.exclude.tags,
                  ids: values.exclude,
                },
              }),
            }

            const newConfig = {
              ...selectedConfig,
              ...(isEmpty(projectsConfigUpdate.projects)
                ? {}
                : projectsConfigUpdate),
            }

            setSelectedConfig(newConfig)
            await saveConfiguration(newConfig)
            triggerMixPanelTagsChangedEvent(selectedAssetType)
          }}
        />
      )}

      <Box sx={{ mt: '50px', mb: '50px', position: 'relative' }}>
        <ContentPanel>
          <ContentPanelHeader>
            YAML representation of your {selectedAssetType} assets
            configuration:
          </ContentPanelHeader>

          {previewMode && (
            <Box
              onClick={() => {
                triggerMixPanelShowFullYamlEvent(selectedAssetType)
                setPreviewMode(false)
              }}
              sx={{
                position: 'absolute',
                height: '151px',
                top: '58px',
                width: '100%',
                cursor: 'pointer',
                zIndex: '1',
                textAlign: 'center',
              }}
            >
              <Box
                sx={{
                  margin: '0 auto',
                  marginTop: '35px',
                  width: '250px',
                  color: '#394747',
                  fontSize: '20px',
                  background: 'rgb(208,208,208, 0.4)',
                  height: '80px',
                  borderRadius: '5px',
                  padding: '10px',
                }}
              >
                Click for full YAML
                <Box>
                  <CloudDownloadOutlined style={{ fontSize: '32px' }} />
                </Box>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              overflow: 'hidden',
              maxHeight: previewMode ? '150px' : '350px',
              transition: 'max-height 0.5s ease-out',
              '#configuration': {
                width: '100% !important',
                opacity: previewMode ? '0.35' : '1',
              },
            }}
          >
            <AceEditor
              readOnly
              height="350px"
              mode="yaml"
              fontSize={16}
              theme="solarized_dark"
              showPrintMargin={canUserEdit}
              showGutter={canUserEdit}
              highlightActiveLine={canUserEdit}
              value={getConfigWithoutOverrides()}
              onChange={(value) => setSelectedConfig(value)}
              name="configuration"
              editorProps={{ $blockScrolling: true }}
            />
          </Box>
        </ContentPanel>
      </Box>

      {[AssetType.Git, AssetType.Host].includes(selectedAssetType) && (
        <ConfigurationTypesMessageBox />
      )}

      <Flex sx={{ mt: '50px' }}>
        {[AssetType.Git, AssetType.Host].includes(selectedAssetType) && (
          <Tooltip title="Use this file in a repo - .spectral/spectral.yaml">
            <Button
              disabled={isSavingAgentConfig}
              type="dashed"
              size="large"
              onClick={saveConfigToSpectralYaml}
              icon={<DownloadOutlined />}
              sx={{ mr: '15px' }}
            >
              Download as spectral.yaml file
            </Button>
          </Tooltip>
        )}

        <Button
          onClick={() => setShowResetConfigModal(true)}
          disabled={!isConfigurationEditable}
          icon={<RollbackOutlined />}
          size="large"
          danger
        >
          Reset {selectedAssetType} configuration
        </Button>
      </Flex>

      {(addNewIgnoreProcess || ignoreIndexToEdit > -1) && (
        <UpsertIgnoreModal
          ignore={
            ignoreIndexToEdit > -1
              ? currentIgnoresDataSource()[ignoreIndexToEdit]
              : null
          }
          mode={ignoreIndexToEdit > -1 ? 'edit' : 'new'}
          onClose={() => {
            setAddNewIgnoreProcess(false)
            setIgnoreIndexToEdit(-1)
          }}
          onSubmit={upsertIgnore}
        />
      )}

      {showResetConfigModal && (
        <ResetConfigurationModal
          selectedAssetType={selectedAssetType}
          onCancel={() => setShowResetConfigModal(false)}
          onOk={applyDefaultConfiguration}
        />
      )}

      {ignoreIndexToDelete > -1 && (
        <DeleteIgnoreModal
          onCancel={() => {
            setIgnoreIndexToDelete(-1)
          }}
          onOk={() => {
            removeIgnore()
            setIgnoreIndexToDelete(-1)
          }}
        />
      )}

      <Box sx={{ mt: '50px', mb: '50px', position: 'relative' }}>
        <Box sx={{ mb: '30px' }}>
          <PageTitle>Fallback</PageTitle>
          <Box sx={{ mb: '7px' }}>
            Set the behavior of the scanner in cases of misconfiguration and
            hardening. By default, the scanner will stop execution and return an
            error code (Strict mode). You can choose to set a fallback mode in
            which the scanner will finish the execution, and print to the screen
            the necessary fallback actions that was taken in order to finish the
            run.
          </Box>
          <Box>
            Supported from Spectral version{' '}
            <strong>
              <code>1.10.208</code>
            </strong>{' '}
            and above.
          </Box>
          <Box sx={{ mb: '7px' }}>
            For more information on the fallback mode, please visit our{' '}
            <a
              href="https://guides.spectralops.io/docs/configuration#fallback-mode"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              docs
            </a>
            .
          </Box>
        </Box>
      </Box>

      <ContentPanel>
        <PanelForm
          onSubmit={async (val) => {
            await saveFallbackMode(val)
          }}
          initialValues={selectedConfig.fallback}
        >
          <Flex
            sx={{
              flexDirection: 'column',
            }}
          >
            <Box sx={{ maxWidth: '90%' }}>
              <RadioButtonFieldSection
                name="fallbackMode"
                key="fallbackMode"
                label="Fallback mode"
                description="Choose that fallback mode. Default is 'Strict'."
                submit
                radioButtonsArray={Object.entries(fallbackValues).map(
                  ([key, conf]) => ({
                    value: conf.value,
                    label: conf.label,
                    key: `fallbackMode${key}`,
                  })
                )}
                disabled={!isConfigurationEditable}
                defaultValue={fallbackValues.Strict.value}
              />
            </Box>
          </Flex>
        </PanelForm>
      </ContentPanel>

      <Box sx={{ mt: '50px', mb: '50px', position: 'relative' }}>
        <Box sx={{ mb: '30px' }}>
          <PageTitle>Hardening</PageTitle>
          <Box sx={{ mb: '7px' }}>
            Control and harden Spectral command over different assets in your
            organization. Violating the policy will either fail the Spectral
            command or ignore the hardened configuration, and continue with
            warning - according to the Fallback mode.
          </Box>
          <Box>
            For more information on hardening and supported Spectral versions,
            please visit our{' '}
            <a
              href="https://guides.spectralops.io/docs/configuration#hardening"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              docs
            </a>
            .
          </Box>
        </Box>
        <ContentPanel>
          <ContentPanelHeader>
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Flex>CLI Settings</Flex>

              <Flex>
                <Button
                  size="small"
                  onClick={() => resetConfiguration(ConfigType.OVERRIDES)}
                  disabled={!isConfigurationEditable}
                >
                  Allow all
                </Button>
              </Flex>
            </Flex>
          </ContentPanelHeader>
          <PanelForm
            onSubmit={async (vals) => {
              await saveOverridesValues(vals)
            }}
            initialValues={selectedConfig.overrides}
          >
            <Flex
              sx={{
                flexDirection: 'column',
              }}
            >
              {hardeningFlags.map((flag) => {
                if (flag.name === HardeningFeatures.MatchIgnores) {
                  return (
                    <Box>
                      <NestedRadioButtonFieldSection
                        primaryRadio={flag}
                        subRadios={flag.subFlags.values}
                        subRadiosPrefix={flag.subFlags.prefix}
                        primaryDefaultValue={hardeningValues.Allow}
                        subDefaultValue={hardeningValues.Restrict}
                        isConfigurationEditable={isConfigurationEditable}
                        primarySubmit={false}
                        onChangeAction={flag.onChangeAction}
                        radioButtonsArrayValues={Object.entries(
                          hardeningValues
                        ).map(([key, value]) => ({
                          value,
                          label: key,
                          key: `${flag.name}_${key}`,
                        }))}
                      />
                    </Box>
                  )
                }
                return (
                  <Box key={flag.name}>
                    <RadioButtonFieldSection
                      key={flag.name}
                      name={flag.name}
                      label={flag.label}
                      labelDescription={flag.flag}
                      description={flag.description}
                      submit
                      radioButtonsArray={Object.entries(hardeningValues).map(
                        ([key, value]) => ({
                          value,
                          label: key,
                          key: `${flag.name}_${key}`,
                        })
                      )}
                      disabled={!isConfigurationEditable}
                      defaultValue={hardeningValues.Allow}
                    />
                  </Box>
                )
              })}
            </Flex>
          </PanelForm>
        </ContentPanel>
      </Box>
    </Box>
  )
}

export default RemoteConfiguration
