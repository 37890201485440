// TODO : Consider consuming these endpoints from another package in the backend
import { hyperfetch } from '@hyperpack/hyperfetch'
import { ReportAssetView } from '@spectral/types'
import qs from 'querystring'
import { CurrentTeamResponse, ClientProps } from './types'

const getLocalStorageHeaders = () => {
  const xSpectralTeamPid = localStorage.getItem('team')
    ? JSON.parse(localStorage.getItem('team'))?.pid?.toString()
    : null
  const xSpectralUserPid = localStorage.getItem('spectralJWT')
    ? JSON.parse(localStorage.getItem('spectralJWT'))?.pid?.toString()
    : null
  return {
    'x-spectral-team-pid': xSpectralTeamPid || null,
    'x-spectral-user-pid': xSpectralUserPid || null,
  }
}

export const client = ({
  bearer,
  throwIfError,
  throwIfBadStatus,
  responseHandler,
}: ClientProps) => {
  const headers = getLocalStorageHeaders()
  const { post, get } = hyperfetch({
    throwIfError,
    throwIfBadStatus,
    responseHandler,
  })
  const jwt = hyperfetch({
    bearer,
    throwIfError,
    throwIfBadStatus,
    baseOpts: {
      headers,
    },
  })
  return {
    auth: {
      login: post<
        any,
        any,
        { token: string; firstName: string; lastName: string },
        any
      >('/api/auth/login'),
      logout: post('api/auth/logout'),
      signup: post<any, any, any, { token: string }>('/api/auth/signup'),
      recover: post('/api/auth/recover'),
      cloudGuardConnect: post('/api/auth/cloud_guard_connect'),
      recoverFinish: post(
        ({ pid, resetToken }) => `/api/auth/recover/${pid}/${resetToken}`
      ),
      recoverValidate: get(
        ({ pid, resetToken }) => `/api/auth/recover/${pid}/${resetToken}`
      ),
      invitationInfo: get(({ invite }) => `/api/auth/invite/${invite}`),
      ssoTeam: get(({ teamPid }) => `/api/auth/team/${teamPid}`),
      ssoLoginTeamPid: post(`/api/auth/sso/team`),
    },
    assets: {
      assetsList: jwt.get(
        ({
          page,
          orgTeams,
          severity,
          detector,
          displayName,
          scannedDateStart,
          scannedDateEnd,
          assetSource,
          assetCategory,
          tag,
          sortBy,
          sortDirection,
          issuesTypes,
        }) =>
          `/api/assets/list?${qs.encode({
            scannedDateStart,
            scannedDateEnd,
            displayName,
            page,
            orgTeams,
            severity,
            detector,
            assetSource,
            assetCategory,
            tag,
            sortBy,
            sortDirection,
            issuesTypes,
          })}`
      ),
      assetsDetails: jwt.post('/api/assets/details'),
      assetDetailsOpenSource: jwt.post('/api/assets/details_open_source'),
      assetsSummary: jwt.get(`/api/assets/assets_summary`),
      assetById: jwt.get<any, any, any, ReportAssetView>(
        ({ assetId }) => `/api/assets/${assetId}`
      ),
      deleteAsset: jwt.del(({ assetId }) => `/api/assets/${assetId}`),
      existByFilters: jwt.get<any, any, any, CurrentTeamResponse>(
        ({ assetSource }) =>
          `/api/assets/asset_exist?${qs.encode({
            assetSource,
          })}`
      ),
    },
    issues: {
      getAllIssues: jwt.get('/api/issues/'),
      exportIssues: jwt.get(
        ({
          output,
          category,
          severity,
          content,
          orgTeams,
          assetName,
          path,
          dates,
          detectorId,
          issueId,
          page,
          pageSize,
          status,
          sorter,
          issueKinds,
          assetId,
          assignees,
          resources,
        }) =>
          `/api/issues/export?${qs.encode({
            output,
            category,
            severity,
            content,
            orgTeams,
            assetName,
            path,
            fromDate: dates && dates[0].toISOString(),
            toDate: dates && dates[1].toISOString(),
            detectorId,
            issueId,
            page,
            pageSize,
            status,
            sorter,
            issueKinds,
            assetId,
            assignees,
            resources,
          })}`
      ),
      secretsSprawl: jwt.get(
        ({ assetId }) => `/api/issues/sprawl/${encodeURIComponent(assetId)}`
      ),
      filterIssues: jwt.get(
        ({
          category,
          severity,
          content,
          orgTeams,
          assetName,
          assetId,
          path,
          exactPaths,
          dates,
          detectorId,
          issueId,
          assignees,
          page,
          pageSize,
          status,
          sorter,
          issueKinds,
          resources,
          packagesNames,
          OpenSourceIssueTypes,
          tag,
        }) =>
          `/api/issues?${qs.encode({
            category,
            severity,
            content,
            orgTeams,
            assetName,
            assetId,
            path,
            exactPaths,
            fromDate: dates && dates[0].toISOString(),
            toDate: dates && dates[1].toISOString(),
            detectorId,
            issueId,
            assignees,
            page,
            pageSize,
            status,
            sorter,
            issueKinds,
            resources,
            packagesNames,
            OpenSourceIssueTypes,
            tag,
          })}`
      ),
      assignIssues: jwt.put('/api/issues/assign'),
      unassignIssues: jwt.put('/api/issues/unassign'),
      ignoreIssue: jwt.post('/api/issues/ignore'),
      unIgnoreIssue: jwt.put('/api/issues/ignore'),
      resolveIssues: jwt.post('/api/issues/resolve'),
      unresolveIssues: jwt.put('/api/issues/resolve'),
      getByAssetId: jwt.get(({ assetId }) => `/api/issues/asset/${assetId}`),
      getUnsupportedScm: jwt.get(`/api/issues/unsupported_scm_example_issue`),
    },
    scanInsights: {
      byAssetId: jwt.get(
        ({ assetId, pagination }) =>
          `/api/scanInsights/${assetId}?${qs.encode({
            page: pagination.page,
            pageSize: pagination.pageSize,
            sortBy: pagination.sortBy,
            sortDirection: pagination.sortDirection,
          })}`
      ),
    },
    teams: {
      currentTeam: jwt.get<any, any, any, CurrentTeamResponse>(
        '/api/teams/current'
      ),
      updateSettings: jwt.put<any, any, any, any>('/api/teams'),
      generateKey: jwt.post(`/api/teams/generate_key`),
      getActivity: jwt.get(`/api/teams/activity`),
      setSettings: jwt.post('/api/teams/settings'),
      setJiraIntegration: jwt.post('/api/teams/settings/integrations/jira'),
      setMondayIntegration: jwt.post('/api/teams/settings/integrations/monday'),
      testPagerDutyService: jwt.post(
        '/api/teams/settings/integrations/pager_duty/test'
      ),
      setPagerDutySettings: jwt.post(
        '/api/teams/settings/integrations/pager_duty'
      ),
      testCustomWebhook: jwt.post(
        '/api/teams/settings/integrations/custom_webhook/test'
      ),
      testEventsWebhook: jwt.post('/api/teams/settings/events_webhook/test'),
      setCustomWebhookSettings: jwt.post(
        '/api/teams/settings/integrations/custom_webhook'
      ),
      setEventsWebhookStatus: jwt.post(
        '/api/teams/settings/events_webhook_status'
      ),
      getSettings: jwt.get(`/api/teams/settings`),
      getUsage: jwt.get(`/api/teams/usage`),
      getAssetMapping: jwt.get(`/api/teams/settings/asset_mapping`),
      updateAssetMapping: jwt.post(`/api/teams/settings/asset_mapping`),
      setMembersSeeAllAssets: jwt.post(
        `/api/teams/settings/members_see_all_assets`
      ),
      setSCMOnPremDomains: jwt.post(`/api/teams/settings/scm/on_prem_domain`),
      setSCMUrlFormats: jwt.post(`/api/teams/settings/scm/url_formats`),
      getSCMsConfigurations: jwt.get(`/api/teams/settings/scm`),
      getOrgTeams: jwt.get(`/api/teams/settings/org_teams`),
      updateOrgTeams: jwt.put(`/api/teams/settings/org_teams`),
      deleteOrgTeams: jwt.del(`/api/teams/settings/org_teams`),
      setAgentConfig: jwt.post('/api/teams/agent_config'),
      resetAgentConfig: jwt.del('/api/teams/agent_config'),
      setAuthSettings: jwt.post('/api/teams/settings/authentication'),
      optInFeatures: jwt.post('/api/teams/features/opt-in'),
    },
    reports: {
      getReportsData: jwt.post(`/api/reports`),
    },
    customRules: {
      getCustomRulesVersions: jwt.get(
        ({ status }) =>
          `/api/custom-rules?${qs.encode({
            status,
          })}`
      ),
      reviewDraftVersion: jwt.post(`/api/custom-rules/review-draft-version`),
      deactivateVersion: jwt.post(`/api/custom-rules/deactivate-version`),
    },
    users: {
      me: jwt.get('/api/users/me'),
      getAssetAssignableUsers: jwt.get<any, any, any, Array<any>>(
        ({ assetId, nameFilter }) =>
          `/api/users/assignableUsers/${encodeURIComponent(
            assetId
          )}?${qs.encode({
            nameFilter,
          })}`
      ),
      getSettings: jwt.get('/api/users/settings'),
      updateSettings: jwt.put('/api/users/settings'),
      dismissAlert: jwt.put('/api/users/dismiss_alert'),
      generateApiKey: jwt.post(`/api/users/regenerate_api_key`),
      resendEmailVerification: jwt.post('/api/users/send_email_verification'),
      verifyEmail: jwt.get(
        (payload) => `/api/users/verify_email/${payload.token}`
      ),
      setOrgTeams: jwt.put<any, any, any, any>('/api/users/org_teams'),
      setRole: jwt.put<any, any, any, any>('/api/users/role'),
      setAccessibility: jwt.put<any, any, any, any>('/api/users/accessibility'),
      list: jwt.post(`/api/users`),
      delete: jwt.del<any, any, any, any>(({ email }) => `api/users/${email}`),
    },
    integrations: {
      createJiraIssue: jwt.post('/api/integrations/jira/issue'),
      getJiraProjects: jwt.get((payload) =>
        payload?.assetId
          ? `/api/integrations/jira/projects?assetId=${payload?.assetId}`
          : `/api/integrations/jira/projects`
      ),
      getMondayBoards: jwt.get(
        (payload) =>
          `/api/integrations/monday/boards/by_workspace?assetId=${payload?.assetId}`
      ),
      getMondayBoardById: jwt.get(
        (payload) =>
          `/api/integrations/monday/boards/by_id?boardId=${payload?.boardId}`
      ),
      createMondayItem: jwt.post('/api/integrations/monday/item'),
    },
    payments: {
      getCheckoutUrl: jwt.post('/api/payments/get-checkout-url'),
      getBillingManagementUrl: jwt.post(
        '/api/payments/get-billing-management-url'
      ),
    },
    invites: {
      removeInvite: jwt.del<any, any, any, any>('/api/invites'),
      addInvite: jwt.post<any, any, any, any>('/api/invites'),
      listInvites: jwt.get<any, any, any, any>('/api/invites'),
    },
  }
}
