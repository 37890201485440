import React, { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { capitalize, isEmpty, compact } from 'lodash'
import { UNKNOWN_DETECTORS } from '@spectral/types'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import {
  ContentPanel,
  ContentPanelHeader,
  FieldSectionLayout,
  PanelForm,
  RadioButtonFieldSection,
  TextFieldSection,
} from '../../../../components/panels/content'
import ErrorMessage from '../../../../components/forms/error-message'
import Banner, { BannerType } from '../../../../components/banner'
import theme from '../../../../common/theme'

enum RuleSelectionMode {
  Include = 'include',
  Exclude = 'exclude',
}

interface SaveConfigurationError {
  errorId: string
  data: string[]
}

interface RulesInputProps {
  selectedAssetType: string
  saveConfigurationError: SaveConfigurationError
  currentIncludeRules?: string[]
  currentExcludeRules?: string[]
  disabled?: boolean
  onSubmit: (rules: { include: string[]; exclude: string[] }) => void
}

const calculateRuleSelectionMode = (
  excludeRules: string[]
): RuleSelectionMode =>
  !isEmpty(excludeRules) ? RuleSelectionMode.Exclude : RuleSelectionMode.Include

const RulesInput: React.FC<RulesInputProps> = ({
  selectedAssetType,
  saveConfigurationError,
  currentIncludeRules = [],
  currentExcludeRules = [],
  disabled = false,
  onSubmit,
}) => {
  const [ruleSelectionMode, setRuleSelectionMode] = useState<RuleSelectionMode>(
    calculateRuleSelectionMode(currentExcludeRules)
  )
  const [currentRules, setCurrentRules] = useState<string>(
    ruleSelectionMode === RuleSelectionMode.Include
      ? currentIncludeRules.join(',')
      : currentExcludeRules.join(',')
  )

  useEffect(() => {
    setCurrentRules(
      ruleSelectionMode === RuleSelectionMode.Include
        ? currentIncludeRules.join(',')
        : currentExcludeRules.join(',')
    )
  }, [currentIncludeRules, currentExcludeRules])

  const handleSubmit = useCallback(
    async (values: { ruleSelectionMode: RuleSelectionMode; rules: string }) => {
      const { ruleSelectionMode: ruleSelectionModeValue, rules } = values
      const rulesArray = compact(rules.split(','))
      await onSubmit({
        include:
          ruleSelectionModeValue === RuleSelectionMode.Include
            ? rulesArray
            : [],
        exclude:
          ruleSelectionModeValue === RuleSelectionMode.Exclude
            ? rulesArray
            : [],
      })
      setRuleSelectionMode(ruleSelectionModeValue)
      setCurrentRules(rules)
    },
    [onSubmit]
  )
  const [isConfirmationShown, setIsConfirmationShown] = useState(false)

  const handleRuleSelectionModeChange = useCallback(
    (e) => {
      if (isEmpty(currentRules)) {
        setRuleSelectionMode(e)
      } else {
        setIsConfirmationShown(true)
      }
    },
    [currentRules]
  )

  const onConfirmationModalClose = useCallback(() => {
    setIsConfirmationShown(false)
  }, [])

  const onConfirm = useCallback(async () => {
    const ruleSelectionModeValue =
      ruleSelectionMode === RuleSelectionMode.Include
        ? RuleSelectionMode.Exclude
        : RuleSelectionMode.Include
    await handleSubmit({ ruleSelectionMode: ruleSelectionModeValue, rules: '' })
    setIsConfirmationShown(false)
  }, [handleSubmit, ruleSelectionMode])

  return (
    <Box sx={{ mt: '50px' }}>
      <ContentPanel>
        <ContentPanelHeader>
          {`Configure rules usage for ${selectedAssetType} scans`}
        </ContentPanelHeader>
        <Banner
          type={BannerType.Warning}
          messageText="These settings will not take effect if Spectral is run with --include-tags or --exclude-tags"
          actionButtonText=""
          actioButtonCallback={null}
          dismissButtonText=""
          dismissButtonCallback={null}
        />

        {saveConfigurationError?.errorId === UNKNOWN_DETECTORS && (
          <ErrorMessage
            error={`Unknown detectors: ${saveConfigurationError.data.join(
              ', '
            )}`}
            showIcon
          />
        )}
        <PanelForm
          onSubmit={handleSubmit}
          initialValues={{
            rules: currentRules,
            ruleSelectionMode,
          }}
        >
          <RadioButtonFieldSection
            name="ruleSelectionMode"
            key="ruleSelectionMode"
            label="Rule Selection Mode"
            description={
              <Box>
                Choose whether to include or exclude the specified Rule IDs in
                your scans. For more information please visit our{' '}
                <a
                  href="https://guides.spectralops.io/docs/configuration#properties"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  docs
                </a>
              </Box>
            }
            radioButtonsArray={[
              {
                value: RuleSelectionMode.Include,
                label: 'Include',
                key: 'include',
              },
              {
                value: RuleSelectionMode.Exclude,
                label: 'Exclude',
                key: 'exclude',
              },
            ]}
            disabled={disabled}
            defaultValue={RuleSelectionMode.Include}
            onChangeAction={handleRuleSelectionModeChange}
            value={ruleSelectionMode}
          />
          <Box sx={{ mb: 3 }}>
            <TextFieldSection
              name="rules"
              label={`${capitalize(ruleSelectionMode)} rule IDs`}
              description="Comma separated detector IDs"
              placeholder="CLD001,DB002,..."
              layout={FieldSectionLayout.Vertical}
              disabled={disabled}
              height={40}
              submit
              merge
            />
          </Box>
        </PanelForm>
        <ConfirmationModal
          handleConfirmationClose={onConfirmationModalClose}
          onConfirm={onConfirm}
          isVisible={isConfirmationShown}
          ruleSelectionMode={ruleSelectionMode}
        />
      </ContentPanel>
    </Box>
  )
}

interface ConfirmationModalProps {
  onConfirm: () => Promise<void>
  handleConfirmationClose: () => void
  isVisible: boolean
  ruleSelectionMode: RuleSelectionMode
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  handleConfirmationClose,
  isVisible,
  ruleSelectionMode,
}) => {
  return (
    <Modal
      title={
        <Flex>
          <Text
            sx={{ color: theme.stylebook.icons.modals.danger.iconColor, mr: 2 }}
          >
            <CloseCircleOutlined translate="no" />
          </Text>
          Change selection mode
        </Flex>
      }
      visible={isVisible}
      okText="Yes"
      okType="danger"
      cancelText="No"
      onOk={async () => {
        await onConfirm()
      }}
      onCancel={handleConfirmationClose}
      maskClosable
    >
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text>
          {`Changing to ${
            ruleSelectionMode === RuleSelectionMode.Include
              ? RuleSelectionMode.Exclude.toString()
              : RuleSelectionMode.Include.toString()
          } mode will reset the rule IDs input.`}
        </Text>
        <Text>Are you sure you want to proceed?</Text>
      </Flex>
    </Modal>
  )
}

export default RulesInput
