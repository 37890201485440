import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NotificationSettings from '../../../../blocks/settings/teams/notification/view'
import { select } from '../../../../redux/store'

const NotificationSettingsConnected = () => {
  const dispatch = useDispatch()
  const { emailSettings, slackSettings, msTeamsSettings } = useSelector(
    select.Teams.teamSettings
  )

  return (
    <NotificationSettings
      setTeamSettings={dispatch.Teams.setTeamSettings}
      enabled={emailSettings.enabled}
      enableWeeklyDigestReport={emailSettings.enableWeeklyDigestReport}
      enableDailyDigestReport={emailSettings.enableDailyDigestReport}
      slackIgnoreActionEnabled={{
        status: slackSettings.slackIgnoreActionEnabled,
        isShown: slackSettings.enabled,
      }}
      msTeamsIgnoreActionEnabled={{
        status: msTeamsSettings.msTeamsIgnoreActionEnabled,
        isShown: msTeamsSettings.enabled,
      }}
    />
  )
}

export default NotificationSettingsConnected
