import { RoleType, UserView } from '@spectral/types'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import { FetchStatus, sdkClient } from '../../store'
import { empty, loaded } from '../../utils'

type UsersData = { users: Array<UserView>; totalUsers: number }
type UsersState = {
  users: {
    fetchStatus: FetchStatus
    data: UsersData
  }
}
const initialState: UsersState = {
  users: empty({ users: [], totalUsers: 0 }),
}

export const usersModel = {
  state: initialState,
  reducers: {
    set(
      state: UsersState,
      data: { users: Array<UserView>; totalUsers: number }
    ) {
      return { ...state, users: loaded(data) }
    },
    removeByEmail(state: UsersState, email: string) {
      const newUsers = state.users.data.users.filter(
        (user) => user.username !== email
      )
      return { ...state, users: loaded(newUsers) }
    },
    upsertUser(state: UsersState, userToUpdate: UserView) {
      const users = state.users.data.users.map((user) => {
        return user.pid === userToUpdate.pid ? userToUpdate : user
      })
      return set(state, 'users.data.users', users)
    },
  },
  effects: (dispatch: any) => ({
    async fetchUsers(params) {
      const { data } = (await sdkClient.users().list({ data: params })) as {
        data: { totalUsers; users }
      }
      dispatch.Users.set(data)
    },
    async deleteByEmail(email: string) {
      await sdkClient
        .users()
        .delete({ params: { email: encodeURIComponent(email) } })
    },
    async setOrgTeams({
      orgTeams,
      userPid,
    }: {
      orgTeams: Array<string>
      userPid: string
    }) {
      const {
        data: { user },
      } = await sdkClient.users().setOrgTeams({ data: { orgTeams, userPid } })
      dispatch.Users.upsertUser(user)
    },
    async setRole({ role, userPid }: { role: RoleType; userPid: string }) {
      const {
        data: { user },
      } = await sdkClient.users().setRole({ data: { role, userPid } })
      dispatch.Users.upsertUser(user)
    },
    async setAccessibility({
      canAccessAllAssets,
      userPid,
    }: {
      canAccessAllAssets: boolean
      userPid: string
    }) {
      const {
        data: { user },
      } = await sdkClient
        .users()
        .setAccessibility({ data: { canAccessAllAssets, userPid } })

      dispatch.Users.upsertUser(user)
      return user
    },
  }),
  selectors: (slice, createSelector, _hasProps) => ({
    listUsers() {
      return createSelector(
        slice,
        (rootState) => rootState.Auth.user,
        (rootState) => rootState.loading,
        (userState, currentUser, loadingState) => {
          const isLoading = loadingState.effects.Users.fetchUsers > 0
          const users = userState.users.data.users.map((user) => ({
            ...user,
            isCurrentUser: currentUser.username === user.username,
          }))

          return {
            users,
            totalUsers: userState.users.data.totalUsers,
            isLoadingUsers: isLoading,
          }
        }
      )
    },
    canViewOrganizationSettings() {
      return createSelector(
        (rootState) => rootState.Auth.user,
        (currentUser) => {
          const { role } = currentUser
          return [RoleType.Admin, RoleType.Owner].includes(role)
        }
      )
    },
    isSettingRole() {
      return createSelector(
        (rootState) => rootState.loading,
        (loadingState) => {
          return loadingState.effects.Users.fetchUsers > 0
        }
      )
    },
    isSettingOrgTeams() {
      return createSelector(
        (rootState) => rootState.loading,
        (loadingState) => {
          return loadingState.effects.Users.setOrgTeams > 0
        }
      )
    },
    isSettingAccessibility() {
      return createSelector(
        (rootState) => rootState.loading,
        (loadingState) => {
          return loadingState.effects.Users.setAccessibility > 0
        }
      )
    },
    canSeeAllAssets() {
      return createSelector(
        slice,
        (rootState) => rootState.Teams.current,
        (rootState) => rootState.Auth.user,
        (userState, currentTeam, currentUser) => {
          const { membersSeeAllAssets } = currentTeam.data

          const { role, orgTeams, canAccessAllAssets } = currentUser

          if (membersSeeAllAssets || canAccessAllAssets) {
            return true
          }

          if (
            role === RoleType.Owner ||
            (isEmpty(orgTeams) && role === RoleType.Admin)
          ) {
            return true
          }

          return false
        }
      )
    },
  }),
}
