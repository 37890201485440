import React, { useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Button, Tooltip } from 'antd'
import { RoleDisplayText, RoleType } from '@spectral/types'
import { UserNameView } from './common-view/member-username-common-view'
import { SettingOutlined } from '@ant-design/icons'
import EditMemberModal from '../modals/edit-member-modal'

const MemberRow = ({
  member,
  orgTeams,
  removeMember,
  changeMemberAccessibility,
  changeMemberRole,
  changeMemberOrgTeams,
  membersSeeAllAssets,
}) => {
  const [showEditModal, setShowEditModal] = useState(false)

  const handleRoleChange = ({ role }) =>
    changeMemberRole(role, { pid: member.pid })

  const handleOrgTeamsChange = ({ orgTeams }) =>
    changeMemberOrgTeams(orgTeams, member)

  const handleAccessibilityChange = ({ canAccessAllAssets }) =>
    changeMemberAccessibility(canAccessAllAssets, member.pid)

  const handleModalClose = () => setShowEditModal(false)

  const orgTeamsCount = member.orgTeams?.length || 0
  let teamsDisplayText
  if (orgTeamsCount === 0) {
    teamsDisplayText = 'No teams'
  } else if (orgTeamsCount === 1) {
    teamsDisplayText = '1 team'
  } else {
    teamsDisplayText = `${orgTeamsCount} teams`
  }

  return (
    <Flex
      sx={{ mb: '10px', justifyContent: 'space-between' }}
      key={member.username}
    >
      <EditMemberModal
        onOk={handleModalClose}
        onClose={handleModalClose}
        member={member}
        orgTeams={orgTeams}
        onRoleChange={handleRoleChange}
        onChangeMemberAccessibility={handleAccessibilityChange}
        onChangeOrgTeams={handleOrgTeamsChange}
        omRemove={removeMember}
        visible={showEditModal}
        membersSeeAllAssets={membersSeeAllAssets}
      />

      <UserNameView member={member} />
      <Flex>
        <Flex sx={{ width: '160px', lineHeight: '50px' }}>
          {member.role !== RoleType.Owner && (
            <Tooltip title={member.orgTeams.join(', ')}>
              <Text variant="small">{teamsDisplayText}</Text>
            </Tooltip>
          )}
        </Flex>
        {!membersSeeAllAssets && (
          <Flex
            sx={{ textAlign: 'center', width: '200px', lineHeight: '50px' }}
          >
            <Text variant="small">
              {member.canAccessAllAssets ? 'All assets' : 'Teams assets'}
            </Text>
          </Flex>
        )}
        <Text variant="small" sx={{ width: '110px', lineHeight: '50px' }}>
          <Box>{RoleDisplayText[member.role]}</Box>
        </Text>
        <Box sx={{ textAlign: 'center', width: '100px', lineHeight: '50px' }}>
          {member.role !== RoleType.Owner && (
            <Button
              size="small"
              style={{ width: '32px', height: '32px', marginTop: '-3px' }}
              icon={<SettingOutlined />}
              onClick={() => setShowEditModal(true)}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  )
}

export default MemberRow
