import React from 'react'
import { toLongDisplayNumber } from '@spectral/types'
import { Pagination } from 'antd'

const ConfiguredPagination = ({
  current,
  onChange,
  total,
  pageSize,
  showSizeChanger,
  size = 'default',
  showTotal = null,
  disabled = false,
  hideOnSinglePage = false,
}) => {
  return (
    <Pagination
      current={current}
      onChange={onChange}
      total={total}
      pageSize={pageSize}
      showSizeChanger={showSizeChanger}
      // @ts-ignore
      size={size}
      disabled={disabled}
      showTotal={showTotal}
      hideOnSinglePage={hideOnSinglePage}
      itemRender={(page, type, originalElement) => {
        if (type === 'page') {
          return <span>{toLongDisplayNumber(page)}</span>
        }
        return originalElement
      }}
    />
  )
}

export default ConfiguredPagination
