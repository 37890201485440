import { differenceInDays } from 'date-fns'
import numeral from 'numeral'

export const timeSinceInDays = (since) => {
  const diff = differenceInDays(new Date(), new Date(since))
  const diffDays = diff > 0 ? diff : 1
  const postfix = diffDays > 1 ? 'days' : 'day'
  return `${diffDays} ${postfix}`
}

export function toLongDisplayNumber(value: number) {
  return numeral(value).format('0,0')
}

export function toShortDisplayNumber(value: number) {
  if (value < 1000) {
    return value
  }
  return numeral(value).format('0.0a').toUpperCase()
}

export const removeFilePathSlash = (filePath: string) =>
  filePath[0] === '/' ? filePath.substring(1) : filePath
