import React from 'react'
import { Box, Flex } from 'theme-ui'
import { ResponsivePie } from '@nivo/pie'
import { colors } from './nivo-theme'
import { ContentPanel, ContentPanelHeader } from '../panels/content'
import EmptyState from './empty-state'
import { toShortDisplayNumber } from '@spectral/types'

type PieData = Array<{
  id: string
  label: string
  value: number
  color: string
}>

type Props = {
  data: PieData
  title: string
  onChartItemClick?: (itemData) => void
  colorsScheme?: string[]
  extraOnHeader?
}

const PieChart = ({
  title,
  data,
  onChartItemClick,
  colorsScheme = [],
  extraOnHeader,
}: Props) => {
  return (
    <ContentPanel>
      <ContentPanelHeader>
        <Flex sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Box>{title}</Box>
          {extraOnHeader && <Box>{extraOnHeader}</Box>}
        </Flex>
      </ContentPanelHeader>
      <Box sx={{ height: '300px', p: '24px' }}>
        {data.length !== 0 ? (
          <ResponsivePie
            data={data}
            margin={{ top: 7, bottom: 7, left: 165, right: 10 }}
            innerRadius={0.6}
            padAngle={1}
            valueFormat={toShortDisplayNumber}
            activeOuterRadiusOffset={3}
            colors={
              colorsScheme.length === 0 ? { datum: 'data.color' } : colorsScheme
            }
            arcLabelsTextColor={colors.arcLabelsTextColor}
            enableArcLinkLabels={false}
            arcLabelsSkipAngle={12}
            onMouseEnter={(_data, e) => {
              e.currentTarget.style.cursor = onChartItemClick
                ? 'pointer'
                : 'auto'
            }}
            onClick={onChartItemClick || null}
            legends={[
              {
                anchor: 'top-left',
                direction: 'column',
                justify: false,
                translateX: -150,
                translateY: 5,
                itemsSpacing: 20,
                itemWidth: 80,
                itemHeight: 10,
                itemTextColor: '#11336a',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'circle',
              },
            ]}
          />
        ) : (
          <EmptyState />
        )}
      </Box>
    </ContentPanel>
  )
}

export default PieChart
