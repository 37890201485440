/** @jsx jsx */
import { toLongDisplayNumber } from '@spectral/types'
import { Box, jsx } from 'theme-ui'
import {
  ContentPanel,
  ContentPanelHeader,
} from '../../components/panels/content'
import DetailsList from '../../components/reports/details-list'

const adaptStats = ({
  assets: { total: totalAssets },
  issues: { total: totalIssues },
  scans: { total: totalScans, isShown: isShownScans },
}) => [
  {
    label: 'Assets',
    value: toLongDisplayNumber(totalAssets),
  },
  {
    label: 'Issues',
    value: toLongDisplayNumber(totalIssues || 0),
  },
  {
    label: 'Scans',
    value: toLongDisplayNumber(totalScans),
    isShown: isShownScans,
  },
]

const Stats = ({ stats }) => {
  return (
    <ContentPanel>
      <ContentPanelHeader>Assets Overview</ContentPanelHeader>
      <Box sx={{ px: 24 }}>
        <DetailsList data={adaptStats(stats)} />
      </Box>
    </ContentPanel>
  )
}
export default Stats
