/* eslint-disable react/no-array-index-key */
/** @jsx jsx */
import { useEffect, useState, useCallback } from 'react'
import { Box, Flex, Image, jsx, Text } from 'theme-ui'
import {
  Tooltip,
  Alert,
  Button,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Radio,
  Upload,
} from 'antd'
import { Form, Formik, FieldArray, useField, useFormikContext } from 'formik'
import styled from 'styled-components'
import { FaCircle } from 'react-icons/fa'
import L from 'lodash'
import { AiOutlineDelete } from 'react-icons/ai'
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  UploadOutlined,
} from '@ant-design/icons'

import { CopyButton } from '../buttons/copy'
import theme from '../../common/theme'

export enum FieldSectionLayout {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
}
const DEFAULT_FIELD_LAYOUT = FieldSectionLayout.Horizontal
const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export const PanelForm = ({
  children,
  onSubmit,
  validate = null,
  validationSchema = undefined,
  initialValues = undefined,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    validate={validate}
    enableReinitialize
  >
    <Form>{children}</Form>
  </Formik>
)

export const ContentPanel = ({ children }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      backgroundColor: 'contentPanelBg',
      borderRadius: '4px',
      border: 'contentPanel',
      boxShadow: 'contentPanel',
      marginBottom: '20px',
      '> div.panelSection:last-child': {
        borderBottom: 'none',
      },
      'form:last-child div.panelSection:last-child': {
        borderBottom: 'none',
      },
    }}
  >
    {children}
  </Flex>
)

export const SubmitSection = ({
  disabled = false,
  merge = false,
  danger = false,
  touchToSubmit = true,
  text,
}) => {
  const formik = useFormikContext()
  const formDirty = Object.values(formik.touched).find((v) => v === true)
  return (
    <Flex
      className="panelSection"
      sx={{
        flexDirection: 'row',
        padding: '16px',
        borderBottom: merge ? '' : 'contentPanelSection',
      }}
    >
      <Box sx={{ flex: 1 }} />
      <Box>
        <Button
          loading={formik.isSubmitting}
          disabled={
            disabled || (touchToSubmit && (!formDirty || !formik.isValid))
          }
          htmlType="submit"
          danger={danger}
        >
          {text}
        </Button>
      </Box>
    </Flex>
  )
}

const layouts = {
  [FieldSectionLayout.Horizontal]: {
    alignItems: 'center',
  },
  [FieldSectionLayout.Vertical]: {
    alignItems: 'flex-start',
  },
}

export const CustomFormComponent = ({ children }) => {
  const formik = useFormikContext()
  return children(
    formik.setFieldValue,
    formik.validateForm,
    formik.setFieldTouched,
    formik.values,
    formik.isValid
  )
}

export const CustomSection = ({
  name,
  description,
  fieldAlignEnd = false,
  layout = FieldSectionLayout.Horizontal,
  icon = null,
  children,
}) => {
  return (
    <Flex
      className="panelSection"
      sx={{
        ...layouts[layout],
        flexDirection:
          layout === FieldSectionLayout.Horizontal ? 'row' : 'column',
        padding: layout === FieldSectionLayout.Horizontal ? '16px' : '8px 16px',
        borderBottom: 'contentPanelSection',
      }}
    >
      <Box sx={{ flex: 1, pr: '10px' }}>
        <Flex>
          {icon && (
            <Box sx={{ mr: '5px', mb: '5px' }}>
              <Image
                sx={{
                  width: '25px',
                  height: '25px',
                }}
                src={icon}
              />
            </Box>
          )}
          <Text sx={{ mb: '5px', mr: '3px' }}>{name} </Text>
        </Flex>
        <Text sx={{ fontSize: '14px' }} color="gray.400">
          {description}
        </Text>
      </Box>
      <Box
        sx={{
          flex: 1,
          width: '100%',
          pl: layout === FieldSectionLayout.Horizontal ? '10px' : '0',
        }}
      >
        <Box style={{ textAlign: fieldAlignEnd ? 'end' : 'inherit' }}>
          {children}
        </Box>
      </Box>
    </Flex>
  )
}

export const FormFieldSection = ({
  name,
  label,
  labelDescription = null,
  description,
  renderField,
  fieldAlignEnd = false,
  layout = FieldSectionLayout.Horizontal,
  type = 'text',
  merge = false,
  required = false,
  displayError = true,
  icon = null,
}) => {
  const [formprops, meta, helpers] = useField({ name, type })
  const formik = useFormikContext()
  const shouldDisplayError = meta.touched && meta.error && displayError
  return (
    <Flex
      className="panelSection"
      sx={{
        ...layouts[layout],
        flexDirection:
          layout === FieldSectionLayout.Horizontal ? 'row' : 'column',
        padding: layout === FieldSectionLayout.Horizontal ? '16px' : '8px 16px',
        borderBottom: merge ? 'none' : 'contentPanelSection',
      }}
    >
      <Box sx={{ flex: 1, pr: '10px' }}>
        <label htmlFor={`ffs_${name}`}>
          <Flex>
            {icon && (
              <Box sx={{ mr: '5px', mb: '5px' }}>
                <Image
                  sx={{
                    width: '25px',
                    height: '25px',
                  }}
                  src={icon}
                />
              </Box>
            )}
            <Text sx={{ mb: '5px', mr: '3px' }}>{label} </Text>
            {labelDescription && (
              <Text variant="small" color="gray.400">
                {labelDescription}
              </Text>
            )}
            {required && (
              <Tooltip key={`${name}_required_tooltip`} title="Required">
                {/* https://github.com/ant-design/ant-design/issues/21892 */}
                <span>
                  <FaCircle
                    sx={{ cursor: 'pointer', mt: '3px' }}
                    color="orange"
                    size={8}
                  />
                </span>
              </Tooltip>
            )}
          </Flex>
          <Text sx={{ fontSize: '14px' }} color="gray.400">
            {description}
          </Text>
        </label>
      </Box>
      <Box
        sx={{
          flex: 1,
          width: '100%',
          pl: layout === FieldSectionLayout.Horizontal ? '10px' : '0',
        }}
      >
        <Box
          className={shouldDisplayError && 'ant-form-item-has-error'}
          style={{ textAlign: fieldAlignEnd ? 'end' : 'inherit' }}
        >
          {renderField(
            {
              ...formprops,
              id: `ffs_${name}`,
              name,
            },
            { meta, helpers, formik }
          )}
        </Box>
        {shouldDisplayError ? <div className="error">{meta.error}</div> : null}
      </Box>
    </Flex>
  )
}

export const FileUploadFieldSection = ({
  name,
  label,
  textBelowInput,
  layout,
  required,
  description,
  customOnChange,
  maxItems = 1,
  allowedFileTypes = [],
  merge = false,
  disabled = false,
}) => {
  const [, , helpers] = useField({ name, type: 'file' })
  const { setFieldValue, isSubmitting } = useFormikContext()
  const [filesToUpload, setFilesToUpload] = useState([])

  useEffect(() => {
    if (!isSubmitting) {
      setFilesToUpload([])
    }
  }, [isSubmitting])

  const handleRemove = async () => {
    setFilesToUpload([])
    await helpers.setTouched(false)
  }

  const handleChange = useCallback(
    async (event) => {
      const { file, fileList } = event
      if (fileList.length === 0) {
        setFieldValue(name, null)
        await helpers.setTouched(false)
        return
      }

      const newFileList = [...fileList.slice(-1 * maxItems)]
      const filename = file.name

      customOnChange?.(name, file, setFieldValue)

      setFilesToUpload(newFileList)
      await helpers.setTouched(filename !== '', true)
    },
    [customOnChange, helpers, maxItems, name, setFieldValue]
  )

  return (
    <FormFieldSection
      required={required}
      renderField={(props) => (
        <Box
          sx={{
            '.ant-upload-picture-card-wrapper': {
              textAlign: 'center',
            },
          }}
        >
          <Box
            sx={{
              '.ant-btn': { width: '100%' },
              '.ant-upload': { width: '100%' },
            }}
          >
            <Upload
              beforeUpload={() => false}
              name="avatar"
              fileList={filesToUpload}
              disabled={disabled}
              showUploadList
              accept={allowedFileTypes?.join(',')}
              onChange={handleChange}
              onRemove={handleRemove}
            >
              <Flex sx={{ justifyContent: 'center', width: '100%' }}>
                <Button icon={<UploadOutlined />}>Choose file</Button>
              </Flex>
            </Upload>
          </Box>

          {textBelowInput && (
            <Flex color="green" sx={{ mt: '15px' }}>
              {textBelowInput}
            </Flex>
          )}
        </Box>
      )}
      name={name}
      label={label}
      layout={layout}
      description={description}
      merge={merge}
    />
  )
}

export const NestedRadioButtonFieldSection = ({
  primaryRadio,
  radioButtonsArrayValues,
  primaryDefaultValue,
  subDefaultValue,
  subRadios,
  subRadiosPrefix,
  onChangeAction,
  primarySubmit,
  isConfigurationEditable,
}) => {
  return (
    <Box>
      <RadioButtonFieldSection
        key={primaryRadio.name}
        name={primaryRadio.name}
        label={primaryRadio.label}
        labelDescription={primaryRadio.flag}
        description={primaryRadio.description}
        submit={primarySubmit}
        onChangeAction={(action) =>
          onChangeAction(action, subRadios, primaryRadio)
        }
        merge
        radioButtonsArray={radioButtonsArrayValues}
        disabled={!isConfigurationEditable}
        defaultValue={primaryDefaultValue}
      />
      <Box
        sx={{
          background: theme.stylebook.colors.board,
          padding: '0px',
        }}
      >
        {subRadios.map((subRadio) => {
          return (
            <Box sx={{ marginLeft: '25px' }}>
              <RadioButtonFieldSection
                key={`${subRadiosPrefix}.${subRadio.name}`}
                name={`${subRadiosPrefix}.${subRadio.name}`}
                label={subRadio.label}
                labelDescription={subRadio.flag}
                description={subRadio.description}
                submit
                radioButtonsArray={radioButtonsArrayValues}
                disabled={!isConfigurationEditable}
                defaultValue={subDefaultValue}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export const RadioButtonFieldSection = ({
  name,
  label,
  description,
  required = false,
  merge = false,
  layout = DEFAULT_FIELD_LAYOUT,
  disabled = false,
  defaultValue,
  submit = false,
  radioButtonsArray = [],
  onChangeAction = null,
  labelDescription = null,
  value = null,
}) => {
  const [, meta, helpers] = useField({ name, type: 'radio' })
  return (
    <FormFieldSection
      fieldAlignEnd
      renderField={(_props, { formik }) => {
        const formikValue = L.get(formik.values, name)
        return (
          <Radio.Group
            defaultValue={defaultValue}
            value={value || formikValue}
            disabled={disabled}
            onChange={(event) => {
              const { value: eventValue } = event.target
              helpers.setTouched(eventValue !== meta.initialValue, true)
              helpers.setValue(eventValue, true)
              if (onChangeAction) onChangeAction(eventValue)
              if (submit) {
                formik.submitForm()
              }
            }}
          >
            {radioButtonsArray.map((radioButton) => (
              <Radio.Button value={radioButton.value} key={radioButton.key}>
                {radioButton.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        )
      }}
      name={name}
      label={label}
      layout={layout}
      description={description}
      required={required}
      merge={merge}
      labelDescription={labelDescription}
    />
  )
}

export const InputNumberFieldSection = ({
  name,
  label,
  description,
  allowClear = false,
  required = false,
  merge = false,
  layout = DEFAULT_FIELD_LAYOUT,
  disabled = false,
  placeholder = '',
}) => {
  const [, meta, helpers] = useField({ name, type: 'text' })
  return (
    <FormFieldSection
      renderField={(props) => (
        <InputNumber
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          allowClear={allowClear}
          placeholder={placeholder}
          disabled={disabled}
          onChange={async (event) => {
            const value = event
            await helpers.setValue(value, true)
            await sleep(0) // keeps the cursor from jumping to the end of the input box on every edit
            await helpers.setTouched(value !== meta.initialValue, true)
          }}
        />
      )}
      name={name}
      label={label}
      layout={layout}
      description={description}
      required={required}
      merge={merge}
    />
  )
}

export const InputFieldSection = ({
  name,
  label,
  description = '',
  allowClear = false,
  required = false,
  merge = false,
  layout = DEFAULT_FIELD_LAYOUT,
  disabled = false,
  placeholder = '',
  prefix = '',
  tooltipHint = '',
  icon = null,
  customOnChange = null,
}) => {
  const [, meta, helpers] = useField({ name, type: 'text' })
  return (
    <FormFieldSection
      renderField={(props) => (
        <Tooltip
          trigger={tooltipHint ? ['focus'] : []}
          title={tooltipHint}
          placement="bottomLeft"
        >
          <Input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            allowClear={allowClear}
            addonBefore={prefix}
            placeholder={placeholder}
            disabled={disabled}
            onChange={async (event) => {
              const { value } = event.target
              await helpers.setValue(value, true)
              customOnChange?.(value)
              await sleep(0) // keeps the cursor from jumping to the end of the input box on every edit
              await helpers.setTouched(value !== meta.initialValue, true)
            }}
          />
        </Tooltip>
      )}
      name={name}
      label={label}
      layout={layout}
      description={description}
      required={required}
      merge={merge}
      icon={icon}
    />
  )
}
export const MultiInputFieldSection = ({
  name,
  label,
  description,
  allowClear = false,
  required = false,
  merge = false,
  layout = DEFAULT_FIELD_LAYOUT,
  disabled = false,
  placeholder = '',
  prefix = '',
  addButtonText = 'Add',
  addButtonIcon = null,
  icon = null,
}) => {
  const [formProps, meta, helpers] = useField({ name, type: 'text' })
  console.log(formProps)
  return (
    <FormFieldSection
      displayError={false}
      renderField={(props) => (
        <FieldArray
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          render={(arrayHelpers) => (
            <Flex sx={{ flexDirection: 'column', gap: 3 }}>
              {props.value.map((fieldValue, index) => (
                <Box
                  className={
                    meta.touched &&
                    meta.error?.[index] &&
                    'ant-form-item-has-error'
                  }
                  style={{ textAlign: 'inherit' }}
                  key={index}
                >
                  <Flex sx={{ gap: 2 }}>
                    <Flex
                      sx={{ flexDirection: 'column', width: '100%', gap: 1 }}
                    >
                      <Input
                        value={fieldValue}
                        name={`${props.name}.${index}`}
                        allowClear={allowClear}
                        addonBefore={prefix}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={async (event) => {
                          const { value } = event.target
                          await helpers.setValue(
                            props.value.map((fieldVal, i) =>
                              i === index ? value : fieldVal
                            ),
                            true
                          )
                          await sleep(0) // keeps the cursor from jumping to the end of the input box on every edit
                          await helpers.setTouched(
                            value !== meta.initialValue[index],
                            true
                          )
                        }}
                      />
                      {meta.touched && meta.error?.[index] ? (
                        <div className="error">{meta.error?.[index]}</div>
                      ) : null}
                    </Flex>
                    <AiOutlineDelete
                      sx={{
                        cursor: 'pointer',
                        ml: '10px',
                        alignSelf: 'flex-start',
                        minHeight: '40px',
                      }}
                      onClick={async () => {
                        const newValue = [...props.value]
                        newValue.splice(index, 1)
                        const newValueToCheck = newValue
                          .map((val) => val.trim())
                          .filter(Boolean)
                        const initialValueToCheck = meta.initialValue
                          .map((val) => val.trim())
                          .filter(Boolean)
                        if (L(newValueToCheck).isEqual(initialValueToCheck)) {
                          arrayHelpers.remove(index)
                        } else {
                          await helpers.setValue(newValue, true)
                          await sleep(0) // keeps the cursor from jumping to the end of the input box on every edit
                          await helpers.setTouched(true, true)
                        }
                      }}
                    />
                  </Flex>
                </Box>
              ))}
              <Button
                type="dashed"
                onClick={async () => {
                  arrayHelpers.push('')
                }}
                block
                icon={addButtonIcon}
                disabled={
                  meta.error != null ||
                  formProps.value.some((val) => val === '')
                }
              >
                {addButtonText}
              </Button>
            </Flex>
          )}
        />
      )}
      name={name}
      label={label}
      layout={layout}
      description={description}
      required={required}
      merge={merge}
      icon={icon}
    />
  )
}
export const InputPasswordFieldSection = ({
  name,
  label,
  description,
  required = false,
  merge = false,
  layout = DEFAULT_FIELD_LAYOUT,
  disabled = false,
  placeholder = '',
}) => {
  const [, meta, helpers] = useField({ name, type: 'text' })
  return (
    <FormFieldSection
      renderField={(props) => (
        <Input.Password
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          placeholder={placeholder}
          disabled={disabled}
          onChange={async (event) => {
            const { value } = event.target
            await helpers.setValue(value, true)
            await sleep(0) // keeps the cursor from jumping to the end of the input box on every edit
            await helpers.setTouched(value !== meta.initialValue, true)
          }}
        />
      )}
      name={name}
      label={label}
      description={description}
      layout={layout}
      required={required}
      merge={merge}
    />
  )
}
export const TextFieldSection = ({
  name,
  label,
  description = '',
  height = null,
  required = false,
  layout = DEFAULT_FIELD_LAYOUT,
  merge = false,
  placeholder = '',
  disabled = false,
  submit = false,
  customOnChange = null,
}) => {
  const [, meta, helpers] = useField({ name, type: 'text' })
  const formik = useFormikContext()
  return (
    <FormFieldSection
      renderField={(props) => (
        <Input.TextArea
          style={{ height }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={() => {
            if (submit && meta.touched) {
              formik.submitForm()
            }
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter' && submit) {
              formik.submitForm()
            }
          }}
          onChange={async (event) => {
            const { value } = event.target
            await helpers.setValue(value, true)
            await sleep(0) // keeps the cursor from jumping to the end of the input box on every edit
            await helpers.setTouched(value !== meta.initialValue, true)
            customOnChange?.(value)
          }}
        />
      )}
      name={name}
      label={label}
      description={description}
      required={required}
      layout={layout}
      merge={merge}
    />
  )
}

export const SwitchSection = ({ name, disabled = false, submit = true }) => {
  const [formprops, meta, helpers] = useField({ name, type: 'checkbox' })
  const formik = useFormikContext()
  return (
    <Switch
      checked={formprops.checked}
      disabled={disabled}
      loading={formik.isSubmitting}
      onChange={(val) => {
        helpers.setTouched(val !== meta.initialValue, false)
        helpers.setValue(val)
        if (submit) {
          formik.submitForm()
        }
      }}
    />
  )
}

export const SwitchFieldSection = ({
  name,
  label,
  description,
  disabled = false,
  controlNote = null,
  layout = DEFAULT_FIELD_LAYOUT,
  merge = false,
  submit = false,
  tooltipNote = null,
  checkedChildren = null,
  uncheckedChildren = null,
  loading = false,
}) => (
  <FormFieldSection
    type="checkbox"
    renderField={(props, { helpers, meta, formik }) => {
      return (
        <Space>
          <Tooltip title={tooltipNote}>
            <Switch
              checkedChildren={checkedChildren}
              unCheckedChildren={uncheckedChildren}
              checked={props.checked}
              disabled={disabled}
              loading={formik.isSubmitting || loading}
              onChange={(val) => {
                helpers.setTouched(val !== meta.initialValue, false)
                helpers.setValue(val)
                if (submit) {
                  formik.submitForm()
                }
              }}
            />
          </Tooltip>
          {controlNote}
        </Space>
      )
    }}
    name={name}
    label={label}
    layout={layout}
    description={description}
    merge={merge}
  />
)

export const ButtonField = ({
  name,
  label,
  onClick,
  loading = false,
  description = '',
  buttonType = 'default',
  disabled = false,
  layout = FieldSectionLayout.Horizontal,
  merge = false,
}) => (
  <FormFieldSection
    renderField={(props, { formik }) => (
      <Button
        disabled={disabled}
        // @ts-ignore
        type={buttonType}
        loading={loading}
        onClick={() => {
          onClick(
            formik.setFieldValue,
            formik.validateForm,
            formik.setFieldTouched
          )
        }}
      >
        {label}
      </Button>
    )}
    layout={layout}
    name={name}
    label=""
    merge={merge}
    description={description}
  />
)

const DummyInputWithScroll = ({ isSecret, value }) => {
  const [secretShown, setSecretShown] = useState(isSecret)
  const handleRevealClick = () => {
    setSecretShown(!secretShown)
  }
  return (
    <Flex sx={{ flex: 1 }}>
      {isSecret && (
        <Button
          onClick={handleRevealClick}
          sx={{
            right: 0,
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
        >
          {secretShown ? (
            <EyeOutlined sx={{ fontSize: 'x-large' }} />
          ) : (
            <EyeInvisibleOutlined sx={{ fontSize: 'x-large' }} />
          )}
        </Button>
      )}
      <Flex
        sx={{
          borderRadius: '4px',
          flex: 1,
          justifyContent: 'space-between',
          background: '#fafafa',
          border: 'outline',
          mr: '10px',
          '&::-webkit-scrollbar': {
            height: '3px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#762aeb',
            borderRadius: '8px',
          },
          overflowY: 'hidden',
          overflowX: 'auto',
          lineHeight: '100%',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          px: '10px',
          borderTopLeftRadius: isSecret ? '0px' : '',
          borderBottomLeftRadius: isSecret ? '0px' : '',
          borderLeftWidth: isSecret ? '0px' : '',
        }}
      >
        {!secretShown && <Text>{value}</Text>}
        {secretShown && <Text>****************************</Text>}
      </Flex>
    </Flex>
  )
}

export const ActionFieldSection = ({
  name,
  label,
  description,
  action,
  disabled = false,
  layout = DEFAULT_FIELD_LAYOUT,
  merge = false,
  submit = false,
  isSecret = false,
  value = null,
}) => {
  return (
    <FormFieldSection
      renderField={(props, { meta, formik }) => {
        return (
          <Flex sx={{ alignItems: 'stretch' }}>
            <DummyInputWithScroll
              isSecret={isSecret}
              value={props.value || meta.initialValue}
            />
            <Button
              sx={{ marginRight: '10px' }}
              loading={formik.isSubmitting}
              disabled={disabled}
              onClick={() => {
                if (submit) {
                  formik.submitForm()
                }
              }}
            >
              {action}
            </Button>
            {value && <CopyButton value={value} />}
          </Flex>
        )
      }}
      name={name}
      label={label}
      layout={layout}
      description={description}
      merge={merge}
    />
  )
}

export const CopyFieldSection = ({
  name,
  label,
  description,
  value,
  layout = DEFAULT_FIELD_LAYOUT,
  merge = false,
  isSecret = false,
}) => {
  return (
    <FormFieldSection
      renderField={(props, { meta }) => {
        return (
          <Flex sx={{ alignItems: 'stretch' }}>
            <DummyInputWithScroll
              isSecret={isSecret}
              value={props.value || meta.initialValue}
            />
            <CopyButton value={value} />
          </Flex>
        )
      }}
      name={name}
      label={label}
      layout={layout}
      description={description}
      merge={merge}
    />
  )
}

export const SelectFieldSection = ({
  name,
  label,
  description = '',
  placeholder = '',
  options,
  groupBy = null,
  onRenderOption = null,
  onSelectAction = (_val, _option) => null,
  layout = DEFAULT_FIELD_LAYOUT,
  filterOption = null,
  required = false,
  submit = false,
  merge = false,
  disabled = false,
  notFoundContent = undefined,
  loading = false,
}) => {
  const renderOptions = (opts) =>
    L.map(opts, ({ label: optionLabel, key, value, ...rest }) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Select.Option key={key} value={value} {...rest}>
        {onRenderOption
          ? onRenderOption({ label: optionLabel, value, ...rest })
          : optionLabel}
      </Select.Option>
    ))
  const groups = groupBy ? L(options).groupBy(groupBy).toPairs().value() : null
  return (
    <FormFieldSection
      renderField={(_props, { meta, helpers, formik }) => {
        return (
          <Select
            style={{ width: '100%' }}
            placeholder={placeholder}
            defaultValue={meta.initialValue}
            value={formik.values[name]}
            loading={formik.isSubmitting || loading}
            showSearch={!!filterOption}
            filterOption={filterOption}
            notFoundContent={notFoundContent}
            disabled={disabled}
            onSelect={(val, option) => {
              onSelectAction(val, option)
              helpers.setTouched(val !== meta.initialValue, false)
              helpers.setValue(val)
              if (submit) {
                formik.submitForm()
              }
            }}
          >
            {groups
              ? L.map(groups, ([group, groupOptions]) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Select.OptGroup key={group} label={group}>
                    {renderOptions(groupOptions)}
                  </Select.OptGroup>
                ))
              : renderOptions(options)}
          </Select>
        )
      }}
      name={name}
      label={label}
      type="select"
      description={description}
      layout={layout}
      merge={merge}
      required={required}
    />
  )
}
export const MultiSelectFieldSection = ({
  name,
  label,
  description,
  placeholder = '',
  options,
  disabled = false,
  required = false,
  layout = DEFAULT_FIELD_LAYOUT,
  submit = false,
  merge = false,
  showSearch = false,
  loading = false,
}) => (
  <FormFieldSection
    renderField={(_props, { helpers, meta, formik }) => (
      <Select
        style={{ width: '100%' }}
        disabled={disabled}
        mode="multiple"
        loading={formik.isSubmitting || loading}
        placeholder={placeholder}
        defaultValue={meta.initialValue}
        value={formik.values[name]}
        showSearch={showSearch}
        onChange={(val) => {
          helpers.setTouched(!L.isEqual(val, meta.initialValue), false)
          helpers.setValue(val)
          if (submit) {
            formik.submitForm()
          }
        }}
      >
        {options.map(({ label: optionLabel, key, value, ...rest }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Select.Option key={key} value={value} {...rest}>
            {optionLabel}
          </Select.Option>
        ))}
      </Select>
    )}
    name={name}
    label={label}
    description={description}
    layout={layout}
    merge={merge}
    required={required}
  />
)

export const ContentPanelSection = ({ children, merge = false }) => (
  <Flex
    className="panelSection"
    sx={{
      flex: '1',
      padding: '16px 0px 16px 16px',
      borderBottom: merge ? 'none' : 'contentPanelSection',
    }}
  >
    {children}
  </Flex>
)

export const ContentPanelHeader = ({ children }) => (
  <Flex
    sx={{
      color: 'contentPanelHeaderText',
      borderBottom: 'contentPanel',
      borderRadius: '4px 4px 0px 0px',
      backgroundColor: 'contentPanelHeader',
      padding: '16px',
    }}
  >
    {children}
  </Flex>
)

export const SectionHeader = ({
  title,
  merge = false,
  description = null,
  image = null,
  renderContent = null,
}) => (
  <ContentPanelSection merge={merge}>
    <Flex sx={{ flex: 1 }}>
      {image && (
        <Box sx={{ mr: '10px' }}>
          <img sx={{ width: '60px', height: '60px' }} alt={title} src={image} />
        </Box>
      )}
      <Box sx={{ flex: 1 }}>
        <Text sx={{ fontWeight: '600' }}>{title}</Text>
        {description && <Text>{description}</Text>}
        {renderContent && renderContent()}
      </Box>
    </Flex>
  </ContentPanelSection>
)

export const SectionAlert = styled(Alert)`
  margin-top: 8px;
`

export const PanelImageHeader = ({ title, img }) => (
  <Flex sx={{ alignItems: 'center', display: 'inline-flex' }}>
    {img && <Image sx={{ width: '20px', height: '20px', mr: 2 }} src={img} />}
    <Text>{title}</Text>
  </Flex>
)
