/** @jsx jsx */
import { Text, Flex, Box, jsx } from 'theme-ui'
import { Card, Tooltip } from 'antd'
import { IoCubeOutline } from 'react-icons/io5'
import {
  AssetStats,
  ASSET_SUMMARY_RESULT_SIZE,
  toShortDisplayNumber,
} from '@spectral/types'
import { InfoCircleOutlined } from '@ant-design/icons'
import { SeverityCountTag } from '../../../components/tags/severity'

const AssetsStats = ({
  totalAssets,
  latestAssetsCount,
  filteredAssetCount,
  filteredStats,
}) => {
  const shouldDisplayLatestAssets = totalAssets > latestAssetsCount
  const isFiltered = latestAssetsCount !== filteredAssetCount

  const tooltipTitle = isFiltered
    ? `Showing ${filteredAssetCount} out of ${ASSET_SUMMARY_RESULT_SIZE} latest scanned assets`
    : `Showing the latest ${ASSET_SUMMARY_RESULT_SIZE} scanned assets`
  const assetsContent = isFiltered ? (
    <Flex>
      <Text sx={{ fontWeight: '700' }}>
        {toShortDisplayNumber(filteredAssetCount)}
      </Text>{' '}
      /<Text>{toShortDisplayNumber(latestAssetsCount)}</Text>
    </Flex>
  ) : (
    <Flex sx={{ fontWeight: '700' }}>
      {toShortDisplayNumber(latestAssetsCount)}
    </Flex>
  )
  return (
    <Card
      style={{
        height: '40px',
        width: '540px',
      }}
      size="small"
      type="inner"
    >
      <Flex
        sx={{
          marginTop: '-4px',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ mr: '10px' }}>
          <Flex>
            <Flex sx={{ mt: '1px' }}>
              <IoCubeOutline size={22} />
            </Flex>
            <Flex sx={{ minWidth: '162px' }}>
              <Flex sx={{ alignItems: 'center' }}>
                <Text sx={{ ml: '3px', mr: '3px' }}>Total Assets:</Text>
                {shouldDisplayLatestAssets ? (
                  <Text sx={{ fontWeight: '700' }}>
                    {toShortDisplayNumber(totalAssets)}
                  </Text>
                ) : (
                  assetsContent
                )}
                {shouldDisplayLatestAssets && (
                  <Tooltip title={tooltipTitle}>
                    <InfoCircleOutlined
                      style={{ fontSize: '14px', marginLeft: '7px' }}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Box>
          <Flex
            sx={{
              marginLeft: 'auto',
              alignItems: 'flex-start',
            }}
          >
            {filteredStats[AssetStats.Critical] > 0 && (
              <SeverityCountTag
                sx={{ ml: 1 }}
                count={filteredStats[AssetStats.Critical]}
                countOnly
                key={`filtered_assets_sev_${AssetStats.Critical}`}
                kind={AssetStats.Critical}
              />
            )}
            {filteredStats[AssetStats.High] > 0 && (
              <SeverityCountTag
                sx={{ ml: 1 }}
                count={filteredStats[AssetStats.High]}
                countOnly
                key={`filtered_assets_sev_${AssetStats.High}`}
                kind={AssetStats.High}
              />
            )}
            {filteredStats[AssetStats.Medium] > 0 && (
              <SeverityCountTag
                sx={{ ml: 1 }}
                count={filteredStats[AssetStats.Medium]}
                countOnly
                key={`filtered_assets_sev_${AssetStats.Medium}`}
                kind={AssetStats.Medium}
              />
            )}
            {filteredStats[AssetStats.Low] > 0 && (
              <SeverityCountTag
                sx={{ ml: 1 }}
                count={filteredStats[AssetStats.Low]}
                countOnly
                key={`filtered_assets_sev_${AssetStats.Low}`}
                kind={AssetStats.Low}
              />
            )}
            {filteredStats[AssetStats.Informational] > 0 && (
              <SeverityCountTag
                sx={{ ml: 1 }}
                count={filteredStats[AssetStats.Informational]}
                countOnly
                key={`filtered_assets_sev_${AssetStats.Informational}`}
                kind={AssetStats.Informational}
              />
            )}
            {filteredStats.ignored > 0 && (
              <SeverityCountTag
                sx={{ ml: 1 }}
                count={filteredStats.ignored}
                countOnly
                key="filtered_assets_sev_ignored"
                kind={AssetStats.Ignore}
              />
            )}
          </Flex>
        </Box>
      </Flex>
    </Card>
  )
}

export default AssetsStats
